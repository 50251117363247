import { ColumnTypeEnum } from '@/sdk/model/ColumnDefinitionInputV1';
import { ColDef } from '@ag-grid-community/core';
import { ColumnDefinitionOutputV1 } from '@/sdk';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { FormulaErrorInterface } from '@/formula/formula.types';

export interface MaterializedTableOutput {
  rows: (string | number | boolean | undefined)[][];
  headers: MaterializedTableHeader[];
  hasMore: boolean;
}

export interface ItemTableCell {
  uuid: string;
  fetchedProperties?: { [key: string]: string | undefined };
}

export interface NumericTableCell {
  value: number;
  uom?: string;
}

export interface TextTableCell {
  value?: string;
}

export interface BooleanTableCell {
  value: boolean;
}

export type ProcessedTableCell = ItemTableCell | NumericTableCell | TextTableCell | BooleanTableCell;

export interface ProcessedMaterializedTable {
  rows: ProcessedTableCell[][];
  headers: MaterializedTableHeader[];
  hasMore: boolean;
}

export interface MaterializedTableHeader {
  name: string;
  type: ColumnTypeEnum;
}

export interface ScalingTableColumnDefinition extends ColumnDefinitionOutputV1 {
  /** Item Columns use this field for which Item Property that column is currently displaying */
  propertyToDisplay?: string;
  /** Item columns use this field to keep track of all Properties that have been fetched, in conjunction with
   *  propertyToDisplay, so we can cache the values for each property when we set the results of a Materialized
   *  Table in the store */
  additionalProperties?: string[];
  /** For most columns this will be the columnName, but some columns need a UI override for their name if they are
   * not editable by the user
   */
  displayName: string;
}

export interface MaterializedTableItemColumnPropertyPair {
  uuidColumn: string;
  propertyName: string;
}

export interface MaterializedTablePropertyColumnInput {
  uuidColumn: string;
  propertyNames: string[];
}

export interface FormulaCompileResult {
  success: boolean;
  errors: FormulaErrorInterface[];
}

export const DEFAULT_TABLE_DEFINITION_NAME = 'New Table';

export const PROPERTY_COLUMN_MATCH_SEGMENT = '.properties.';
export const UOM_COLUMN_MATCH_SUFFIX = '_uom';

export const COLUMNS_HIDDEN_FROM_USER: string[] = [SeeqNames.MaterializedTables.DatumIdColumn];

export interface AgGridScalingColumnDefinition extends ColDef {
  field: string;
  headerName: string;
  headerValueGetter: () => string;
}

export interface TableDefinitionAccessSettings {
  /** Contains identities and their permissions */
  securityString?: string;
  /** Workbook ID or undefined to make table global */
  scopedTo?: string;
}

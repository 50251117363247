// @ts-strict-ignore
import React from 'react';
import { Field, FormSpy } from 'react-final-form';
import classNames from 'classnames';
import _ from 'lodash';
import { Icon } from '@seeqdev/qomponents';
import { CheckMarkOrNumber } from '@/core/CheckMarkOrNumber.atom';
import { AdvancedParameters } from '@/core/AdvancedParameters.atom';
import { FakeLink } from '@/core/FakeLink';

export const FormCondition = ({ conditionName, children, show }) => (
  <Field name={conditionName} subscription={{ value: true }} key={`${conditionName}_field`}>
    {() => (show ? children : null)}
  </Field>
);

export const ConditionalFormBlock = ({ isConditional, show, name, children }) => {
  if (isConditional) {
    return (
      <FormCondition conditionName={`${name}_condition`} show={show} key={`${name}_condition`}>
        {children}
      </FormCondition>
    );
  }
  return <React.Fragment key={`${name}_conditionBlock`}>{children}</React.Fragment>;
};

export const FormBlockWithCheckmarkAndNumber = ({ name, number, fieldNames, children, extraClassNames }) => {
  return (
    <div
      className={classNames('flexColumnContainer formElement mb10 mt15 ml5 mr5', extraClassNames)}
      key={name}
      id={`${name}-wrapper`}>
      <FormSpy subscription={{ errors: true, values: true }} key={`${name}_spy`}>
        {({ errors }) => {
          const valid = _.reduce(fieldNames, (acc, cur) => acc && !errors[cur], true);
          return <CheckMarkOrNumber showNumber={!valid} number={number} />;
        }}
      </FormSpy>
      {children}
    </div>
  );
};

export const FormRowBlock = ({ name, testId, extraClassNames, children }) => (
  <div
    className={classNames('flexColumnContainer', extraClassNames)}
    key={`${name}_row`}
    id={name}
    data-testid={testId}>
    {children}
  </div>
);

export const RemovableFormRowBlock = ({
  name,
  testId,
  extraClassNames,
  wrapperClassNames,
  children,
  hideRemoveMessage,
  removeAction,
  message,
}) => (
  <div className={classNames('removableRowWrapper', wrapperClassNames)} data-testid={`${testId}_wrapper`}>
    <div
      className={classNames('flexColumnContainer', extraClassNames)}
      key={`${name}_row`}
      id={name}
      data-testid={testId}>
      {children}
    </div>
    {!hideRemoveMessage && (
      <FakeLink onClick={removeAction} extraClassNames="removeMessage cursorPointer mr8">
        {message}
      </FakeLink>
    )}
  </div>
);

export const AdvancedFormBlock = ({ name, testId, toolName, toolId, toolStore, alternateName, children }) => (
  <div
    className="flexRowContainer advancedPanel toolOptions mb15"
    key={`${name}_wrapper`}
    data-testid={testId}
    id={name}>
    <AdvancedParameters toolName={toolName} toolId={toolId} toolStore={toolStore} alternateName={alternateName}>
      {children}
    </AdvancedParameters>
  </div>
);

export const FormGroupBlock = ({ name, testId, extraClassNames, showBracket, children, showScroll }) => (
  <div
    className={classNames('flexRowContainer', showScroll ? 'flexFill' : 'flexFillOverflow', extraClassNames, {
      leftBracket: showBracket,
    })}
    key={`${name}_wrapper`}
    data-testid={testId || name}
    id={name}>
    {children}
  </div>
);

export const RemovableFormBlock = ({ testId, children, iconAction, hideIcon, extraClassNames, iconHasMargin }) => {
  const icon = !hideIcon && (
    <div
      onClick={iconAction}
      className={classNames('cursorPointer', iconHasMargin ? 'closeIconWrapperWithMargin' : 'closeIconWrapper')}>
      <Icon icon="fa-close" testId="deleteIcon" />
    </div>
  );
  return iconHasMargin ? (
    <div>
      {icon}
      <div className={classNames('removableBlockWrapper', extraClassNames)} data-testid={testId}>
        <>{children}</>
      </div>
    </div>
  ) : (
    <div className={classNames('removableBlockWrapper', extraClassNames)} data-testid={testId}>
      {icon}
      <>{children}</>
    </div>
  );
};

export const DisplayOnlyFormElementWrapper = ({ extraClassNames, testId, children }) => (
  <div className={classNames(extraClassNames)} data-testid={testId}>
    {children}
  </div>
);

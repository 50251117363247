// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@seeqdev/qomponents';
import { SelectAssetModal } from '@/core/SelectAssetModal.molecule';
import { errorToast } from '@/utilities/toast.utilities';
import { SEARCH_TYPES } from '@/main/app.constants';
import { DISPLAYS_CATEGORY } from '@/displays/displays.constants';
import { computeMoveDisplayAsset } from '@/displays/displays.utilities';
import { moveDisplay } from '@/displays/displays.actions';
import { doTrack } from '@/track/track.service';
import { sqModalSearchStore, sqWorkbenchStore } from '@/core/core.stores';
import { exploreAssetSearchActions } from '@/search/search.actions';
import { SeeqNames } from '@/main/app.constants.seeqnames';

interface MoveDisplayModalIF {
  display: { id: string; name: string; ancestors: { id: string }[] };
  onClose: () => void;
}

export const MoveDisplayModal: React.FunctionComponent<MoveDisplayModalIF> = (props) => {
  const EXCLUDE_GLOBALLY_SCOPED = true;
  const { display, onClose } = props;
  const { t } = useTranslation();
  const [assetId, setAssetId] = useState<string>();
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    const initialAssetId = computeMoveDisplayAsset(display);
    exploreAssetSearchActions(
      'modal',
      initialAssetId,
      SEARCH_TYPES,
      false,
      sqWorkbenchStore.stateParams.workbookId,
      EXCLUDE_GLOBALLY_SCOPED,
    )
      .then(() => {
        setIsInitialized(true);
      })
      .catch((error) => errorToast({ httpResponseOrError: error }));
  }, []);

  const isValidAsset = (item): Promise<boolean> => {
    const isScopedToWorkbook = item.scopedTo === sqWorkbenchStore.stateParams.workbookId;
    const isWorkbookScopedSearch = sqModalSearchStore.mode === 'search';
    const isNotCurrentParentAsset = item.id !== display?.ancestors?.slice(-1)?.[0]?.id;
    const isValid = (isScopedToWorkbook || isWorkbookScopedSearch) && isNotCurrentParentAsset;
    return Promise.resolve(isValid);
  };

  const updateDisplayParent = (parentId) => {
    onClose();
    return moveDisplay(display.id, parentId);
  };

  const cancelMoveDisplay = () => {
    doTrack(DISPLAYS_CATEGORY, 'move', 'cancel');
    onClose();
  };

  const footer = (
    <div className="flexColumnContainer flexJustifyEnd p10">
      <Button
        onClick={cancelMoveDisplay}
        label={t('DISPLAYS.MODAL.MOVE.CANCEL')}
        extraClassNames="mr20"
        testId="moveDisplayCancelButton"
      />
      <Button
        onClick={() => {
          updateDisplayParent(assetId);
        }}
        disabled={!assetId}
        label={t('DISPLAYS.MODAL.MOVE.MOVE')}
        variant="theme"
        testId="moveDisplayMoveButton"
      />
    </div>
  );

  const title = t('DISPLAYS.MODAL.MOVE.HEADER');

  const body = (
    <div className="alert alert-info" data-testid="moveDisplayHelpText">
      {t('DISPLAYS.MODAL.MOVE.HELP_TEXT', { DISPLAY_NAME: display?.name })}
    </div>
  );

  return isInitialized ? (
    <SelectAssetModal
      onClose={cancelMoveDisplay}
      iconPartialTooltipKey="DISPLAYS.MODAL.MOVE.TOOLTIPS"
      onSelect={(item) => {
        setAssetId(item.id);
      }}
      validateItem={isValidAsset}
      selectedAssetIds={assetId}
      scopeIds={[sqWorkbenchStore.stateParams.workbookId]}
      excludeGloballyScoped={true}
      body={body}
      header={{
        title,
      }}
      footer={footer}
      testId="moveDisplayModal"
      searchTypes={[SeeqNames.Types.Asset]}
    />
  ) : null;
};

import { useEffect, useState } from 'react';
import { Editor } from '@ckeditor/ckeditor5-core';

/**
 * This hook enables listening to a CK Editor event and forcing a rerender on any call to the event.
 *
 * @example
 * const Component = ({ resizeEventName, sqReportEditor }) => {
 *   const { width, height } = useCkListener(sqReportEditor.getGlobalInstance(), resizeEventName, {width: 10,
 *   height: 5});
 *   return <OtherComponent width={width} height={height} />
 * }
 *
 * @param editor - The CK editor instance to listen with
 * @param event - The event to listen for
 * @param defaultValue - If provided, will be the first value that this hook returns
 * @return If the event has not been triggered, it will return undefined or the provided default value. If it has
 * been triggered, it returns whatever was passed to the event
 */
export function useCkListener(editor: Editor, event: string, defaultValue: any = undefined): any {
  const [result, setResult] = useState(defaultValue);
  useEffect(() => {
    editor.on(event, (_, newValue) => setResult(newValue));
  }, []);
  return result;
}

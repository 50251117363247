// @ts-strict-ignore
import _ from 'lodash';
import moment from 'moment-timezone';
import { API_TYPES, NG_IF_WAIT, NUMBER_CONVERSIONS } from '@/main/app.constants';
import { AssetSelectionInputV1 } from 'sdk/model/AssetSelectionInputV1';
import { ContentOutputV1 } from 'sdk/model/ContentOutputV1';
import { DateRangeInputV1 } from 'sdk/model/DateRangeInputV1';
import { sqContentApi } from '@/sdk/api/ContentApi';
import { sqItemsApi } from '@/sdk/api/ItemsApi';
import { sqAnnotationsApi } from '@/sdk/api/AnnotationsApi';
import { sqWorkbooksApi } from '@/sdk/api/WorkbooksApi';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import {
  canModifyDocument,
  clearPropertyOverridesForContent,
  computeCapsuleOffset,
  forceRefreshContentUsingAssetSelection,
  forceRefreshContentUsingDate,
  formatAssetSelectionFromApiOutput,
  formatAssetSelectionToApiInput,
  formatContentFromApiOutput,
  formatContentToApiInput,
  formatDateRangeFromApiOutput,
  formatDateRangeToApiInput,
  getStrippedAndValidatedDocument,
  maybeClearVisualizationSpecificState,
  refreshAllContent,
  refreshContentUsingDate,
  replaceContentIfExists,
  subscribeToReport,
} from '@/annotation/reportContent.utilities';
import { sqReportStore, sqWorkbenchStore, sqWorkbookStore, sqWorkstepsStore } from '@/core/core.stores';
import { setWorkBook } from '@/workbook/workbook.utilities';
import { getWorksheet } from '@/worksheets/worksheets.utilities';
import { debounceAsync, isViewOnlyWorkbookMode } from '@/utilities/utilities';
import { errorToast, warnToast } from '@/utilities/toast.utilities';
import { emit } from '@/utilities/socket.utilities';
import { flux } from '@/core/flux.module';
import { PUSH_IGNORE } from '@/core/flux.service';
import { generate } from '@/utilities/screenshot.utilities';
import { toggleEditor } from '@/utilities/migration.utilities';
import {
  AssetSelection,
  Content,
  ContentDisplayMetadata,
  DateRange,
  DateRangeSwapInfo,
  DocumentLayout,
  DocumentMarginUnits,
  DocumentPaperSize,
  InteractiveReportContent,
  Margin,
  ReportContentSummary,
  ReportEditingStateEvent,
  ReportSchedule,
  ReportUpdateMessage,
  ReportUpdateMessageType,
  ReportUpdateMessageWithSpecificUpdates,
  SandboxMode,
} from '@/reportEditor/report.constants';
import { DEFAULT_WORKBOOK_STATE } from '@/workbook/workbook.constants';
import { doTrack } from '@/track/track.service';
import { isAdvancedDateRangeSwapEnabled } from '@/services/systemConfiguration.utilities';

import { nameAndDescriptionFromDocument } from '@/utilities/annotation.utilities';
import { t } from 'i18next';
import { computeCapsules, computeScalar, getDependencies } from '@/utilities/formula.utilities';
import { setTimezone } from '@/worksheet/worksheet.actions';
import {
  getCursorPosition,
  getHtmlReportEditor,
  getScrollOffset,
  setCursorPosition,
  setScrollOffset,
} from '@/utilities/reportEditor.utilities';
import { DEBOUNCE } from '@/core/core.constants';
import { getWorkbooksLink, goTo } from '@/main/routing.utilities';
import { sqTimezones } from '@/utilities/datetime.constants';
import { setHtmlCKEditor } from '@/annotation/ckEditor.utilities';
import { formatMessage } from '@/utilities/logger.utilities';

const DATA_SEEQ_CONTENT_PENDING_REGEX = new RegExp(
  `${SeeqNames.TopicDocumentAttributes.DataSeeqContentPending}="(.+?)"`,
  'g',
);

export const debouncedImageStateChanged = _.debounce(imageStateChanged, DEBOUNCE.MEDIUM);
export const debouncedOnReport = debounceAsync(onReport);

/**
 * Finds an existing report and sets it in the store.
 *
 * @param reportId - a report ID
 * @param transformData - a export function that transforms the data if needed, it will return true/false if we
 * need to reload the data
 * @param upgradeAttempted - Only used when the export function is called recursively to ensure that it doesn't get
 *   stuck in a reload loop if the upgrade failed
 * @returns {Promise} that resolves when the report, its content, and its date ranges are loaded
 */
export function loadReport(
  reportId,
  transformData = (d) => Promise.resolve(false),
  upgradeAttempted = false,
): Promise<any> {
  if (sqReportStore.sandboxMode.enabled && sqReportStore.id === reportId) {
    // We do not want to re-load when we are in sandbox mode.
    return Promise.resolve();
  }
  return Promise.all([
    sqAnnotationsApi.getAnnotation({ id: reportId }),
    sqContentApi.getContentsWithAllMetadata({ reportId }),
  ])
    .then((dataArray) => {
      return transformData(dataArray).then((shouldReload) => {
        // we need to reload everything once it got saved
        if (shouldReload) {
          return Promise.all([
            sqAnnotationsApi.getAnnotation({ id: reportId }),
            sqContentApi.getContentsWithAllMetadata({ reportId }),
          ]);
        } else {
          return dataArray;
        }
      });
    })
    .then(([{ data: report }, { data }]) => {
      if (!report.ckEnabled) {
        doTrack('CKEditor', 'Upgrade');
        return toggleEditor(reportId).then(() => loadReport(reportId, transformData, true));
      }
      manageLoadedReportContent(report, data);
      doTrack('Topic', 'Document Loaded', report.fixedWidth ? 'With Fixed Width' : 'With Auto Width');
    })
    .catch((response) => {
      // CRAB-20279: If report content fails to load, show the error to the user and go home.
      errorToast({ httpResponseOrError: response });
      goTo(getWorkbooksLink());
      return [];
    });
}

export function manageLoadedReportContent(report, data) {
  setTimezone(report.timezone === '' ? undefined : _.find(sqTimezones.timezones, { name: report.timezone }));

  flux.dispatch('REPORT_RESET');
  flux.dispatch('REPORT_REMOVE_ALL_CONTENT', undefined, PUSH_IGNORE);
  flux.dispatch('REPORT_REMOVE_ALL_DATE_RANGES', undefined, PUSH_IGNORE);

  // The content must be set before the report to ensure hash code is present for the image urls
  // Make sure we're getting date ranges from the report
  const contentItems = _.map(data.contentItems, (contentWithMetadata) =>
    formatContentFromApiOutput(contentWithMetadata),
  );

  flux.dispatch('REPORT_SET_ALL_CONTENT', contentItems, PUSH_IGNORE);
  const dateRanges = _.map(data.dateRanges, (dateRange) => formatDateRangeFromApiOutput(dateRange));
  flux.dispatch('REPORT_SET_ALL_DATE_RANGES', dateRanges, PUSH_IGNORE);
  const assetSelections: AssetSelection[] = _.map(data.assetSelections, (assetSelection) =>
    formatAssetSelectionFromApiOutput(assetSelection),
  );
  flux.dispatch('REPORT_SET_ALL_ASSET_SELECTIONS', assetSelections, PUSH_IGNORE);
  flux.dispatch('REPORT_SET', report, PUSH_IGNORE);
}

let loadingSandboxModePromise: Promise<void> | undefined;
// A map of update request group keys to promises for documents that are being updated.
const currentUpdateRequests = {};
// A map of update request group keys to {id, document } to update. Used to defer update requests when one is
// already in process.
const deferredUpdateRequests = {};

/**
 * Updates the report on the backend and sets the report in the report store.
 *
 * @param id - report ID
 * @param document - the HTML document
 * @param noEmit - when true, no ‘content changed’ message is emitted to other viewers of this report
 * @param  stepToNow - if true, steps scheduled report up to now
 * @returns {Promise} a promise that resolves when the report has been updated
 */
export function updateReport(
  id: string,
  document = getHtmlReportEditor(),
  noEmit = false,
  stepToNow = false,
): Promise<any> {
  const isSandboxActive = sqReportStore.sandboxMode.enabled;

  if (isSandboxActive) {
    document = sqReportStore.document;
  }
  const workbookId = isSandboxActive
    ? sqReportStore.sandboxMode.sandboxedWorkbookId
    : sqWorkbenchStore.stateParams.workbookId;
  const worksheetId = isSandboxActive
    ? sqReportStore.sandboxMode.sandboxedWorksheetId
    : sqWorkbenchStore.stateParams.worksheetId;

  exposedForTesting.onEditingStateEvent(ReportEditingStateEvent.SaveStarted);
  const updateRequestGroup = `reportSave-${id}`;
  if (_.isUndefined(document)) {
    return Promise.reject('Document is undefined');
  }
  if (currentUpdateRequests[updateRequestGroup]) {
    // this will overwrite a previously deferred request for this group, but that's OK
    deferredUpdateRequests[updateRequestGroup] = { id, document };
    return currentUpdateRequests[updateRequestGroup];
  } else {
    const { description, name } = nameAndDescriptionFromDocument(document);

    // Update the document to include img[src] if not available to account for the front end transitioning the
    // loadingSpinners
    let strippedAndValidatedDocument = getStrippedAndValidatedDocument(document);

    currentUpdateRequests[updateRequestGroup] = sqAnnotationsApi
      .updateAnnotation(
        {
          name,
          description,
          type: API_TYPES.REPORT,
          document: strippedAndValidatedDocument,
          reportInput: {
            cronSchedule: sqReportStore.reportSchedule?.cronSchedule,
            background: !!sqReportStore.reportSchedule?.background,
            enabled: !!sqReportStore.reportSchedule?.enabled,
            stepToNow,
          },
        },
        { id },
      )
      .then(({ data: report }) => {
        onEditingStateEvent(ReportEditingStateEvent.SaveComplete);
        if (!noEmit) {
          exposedForTesting.emitReport(worksheetId);
        }

        generate({ workbookId, worksheetId, defer: true, workstepId: sqWorkstepsStore.current.id, viewKey: 'TOPIC' });
        return sqContentApi.getContentsWithAllMetadata({ reportId: report.id }).then(({ data }) => {
          if (id === sqReportStore.id) {
            const contentItems = _.map(data.contentItems, (contentWithMetadata) =>
              formatContentFromApiOutput(contentWithMetadata),
            );
            flux.dispatch('REPORT_SET_ALL_CONTENT', contentItems);
            const dateRanges = _.map(data.dateRanges, (dateRange) => formatDateRangeFromApiOutput(dateRange));
            flux.dispatch('REPORT_SET_ALL_DATE_RANGES', dateRanges);
            const assetSelections = _.map(data.assetSelections, (assetSelection) =>
              formatAssetSelectionFromApiOutput(assetSelection),
            );
            flux.dispatch('REPORT_SET_ALL_ASSET_SELECTIONS', assetSelections);
            flux.dispatch('REPORT_SET', { ...report, document: strippedAndValidatedDocument }, PUSH_IGNORE);
            return report;
          }
        });
      })
      .catch((error) => {
        onEditingStateEvent(ReportEditingStateEvent.Offline);
        errorToast({ httpResponseOrError: error });
      })
      .finally(() => {
        delete currentUpdateRequests[updateRequestGroup];
        if (deferredUpdateRequests[updateRequestGroup]) {
          const deferredUpdateData = deferredUpdateRequests[updateRequestGroup];
          delete deferredUpdateRequests[updateRequestGroup];
          return updateReport(deferredUpdateData.id, deferredUpdateData.document);
        }
      });
    return currentUpdateRequests[updateRequestGroup];
  }
}

export function exitSandboxMode() {
  // Deactivate schedule if it exists, to prevent wasted jobs running
  exposedForTesting
    .saveReportSchedule({
      enabled: false,
      background: false,
      cronSchedule: sqReportStore.reportSchedule?.cronSchedule,
    })
    .then(() =>
      getWorksheet(sqWorkbookStore.workbookId, sqReportStore.sandboxMode.originalWorksheetId).then(({ reportId }) =>
        extraExposedForTesting.loadReport(reportId).then(() => {
          viewExposedForTesting.setReportView(sqReportStore.document);
          subscribeToReport(reportId);
        }),
      ),
    );
  doTrack('Sandbox Mode', 'Exit Sandbox');
}

/**
 * Parses the HTML and returns a list of Seeq content ids
 *
 * @param {string} document - HTML document contents to parse
 * @returns {string[]} An array of seeq content
 */
export function parseSeeqContentIdsFromHtml(document: string): string[] {
  if (!document) return [];
  return _.map([...document.matchAll(DATA_SEEQ_CONTENT_PENDING_REGEX)], ([, id]) => id);
}

/**
 * Parses the HTML and returns a list of pending Seeq content ids
 *
 * @param {string} document - HTML document contents to parse
 * @returns {string[]} An array of pending Seeq content
 */
export function parsePendingSeeqContentIdsFromHtml(document: string): string[] {
  if (!document) return [];
  return _.map([...document.matchAll(DATA_SEEQ_CONTENT_PENDING_REGEX)], ([, id]) => id);
}

export function imageStateChanged() {
  flux.dispatch('REPORT_IMAGE_STATE_CHANGED', null, PUSH_IGNORE);
}

export function iframeRefreshCountChanged() {
  flux.dispatch('REPORT_IFRAME_REFRESH_COUNT_CHANGED', null, PUSH_IGNORE);
}

/**
 * Sets the editing state in the report store
 *
 * @param {string} event - the editing state event that occurred
 */
export function onEditingStateEvent(event: ReportEditingStateEvent) {
  flux.dispatch('REPORT_EDITING_STATE_EVENT', { event }, PUSH_IGNORE);
}

/**
 * Called when the user's connection is created, broken, or restored
 *
 * @param {boolean} isOffline - whether the connection is offline or not
 */
export function setIsOffline(isOffline) {
  onEditingStateEvent(isOffline ? ReportEditingStateEvent.Offline : ReportEditingStateEvent.Online);
}

/**
 * Fetches a single content along with associated date range and asset selection used in the current report,
 * optionally populating in sqReportStore.
 *
 * @param {string} id - ID of content to fetch
 * @param {boolean} [populateStore] - true to populate fetched content and dateRange in report store
 * @returns {Promise} that resolves when the content and date range have been retrieved and populated in the store.
 */
export function fetchContent(
  id: string,
  populateStore = true,
): Promise<{
  content: Content;
  dateRange: DateRange;
  assetSelection: AssetSelection;
}> {
  return sqContentApi.getContent({ id }).then(({ data }) => processContentResponse(data, populateStore));
}

/**
 * Converts a single ContentOutputV1 object into its Content, DateRange and AssetSelection counterparts, optionally
 * populating them in the store.
 *
 * @param contentOutput
 * @param populateStore
 * @returns {Object} containing .content, .dateRange and .assetSelection objects
 */
export function processContentResponse(
  contentOutput: ContentOutputV1,
  populateStore = true,
): {
  content: Content;
  dateRange: DateRange;
  assetSelection: AssetSelection;
} {
  const content = formatContentFromApiOutput(contentOutput);
  let dateRange;
  if (populateStore) {
    flux.dispatch('REPORT_SET_CONTENT', content, PUSH_IGNORE);
  }

  // If this content has a dateRange, populate it in store
  if (contentOutput.dateRange) {
    dateRange = formatDateRangeFromApiOutput(contentOutput.dateRange);
    if (populateStore) {
      flux.dispatch('REPORT_SET_DATE_RANGE', dateRange, PUSH_IGNORE);
    }
  }

  let assetSelection;
  if (contentOutput.assetSelection) {
    assetSelection = formatAssetSelectionFromApiOutput(contentOutput.assetSelection);
    if (populateStore) {
      flux.dispatch('REPORT_SET_ASSET_SELECTION', assetSelection, PUSH_IGNORE);
    }
  }

  return { content, dateRange, assetSelection };
}

/**
 * Fetches specified contents along with associated date ranges, populating in
 * sqReportStore.
 *
 * @returns {Promise} that resolves when the contents and date ranges have been retrieved and populated in the store.
 */
export function fetchMultipleContent(contentIds: string[]): Promise<Content[]> {
  return (
    _.chain(contentIds)
      .map((id: string) => fetchContent(id))
      .thru((p) => Promise.all(p))
      .value() as Promise<{ content: Content }[]>
  ).then((allContent) => allContent.map((result) => result.content)) as Promise<Content[]>;
}

/**
 * Fetches a single date range used in the current report, populating in sqReportStore.
 *
 * @param {string} id - ID of dateRange to fetch
 * @param {boolean} [populateStore] - true to populate fetched dateRange in report store
 * @returns {Promise} that resolves when the date range has been retrieved.
 */
export function fetchDateRange(
  id: string | undefined,
  populateStore = true,
): Promise<{ dateRange: DateRange; contentIds: string[] }> {
  return sqContentApi.getDateRange({ id }).then(({ data }) => {
    const dateRange = formatDateRangeFromApiOutput(data);
    if (populateStore) {
      flux.dispatch('REPORT_SET_DATE_RANGE', dateRange, PUSH_IGNORE);
    }
    return {
      dateRange,
      contentIds: data?.content?.map?.((contentItemPreview) => contentItemPreview.id),
    };
  });
}

/**
 * Updates the report's timezone and the worksheet store's timezone.
 * @returns A promise that resolves when API returns a response, the content store has been updated, and content
 * images have begun refreshing.
 */
export function updateTimezone(timezone?: { name: string }): Promise<any> {
  if (!sqWorkbookStore.isReportBinder) {
    return Promise.resolve();
  }

  const params = {
    id: sqReportStore.id,
    propertyName: SeeqNames.Properties.Timezone,
  };
  let promise: Promise<void>;
  if (timezone) {
    const body = { value: timezone.name };
    promise = sqItemsApi.setProperty(body, params).then(() => setTimezone(timezone));
  } else {
    promise = sqItemsApi.deleteProperty(params).then(() => setTimezone(undefined));
  }

  return promise.then(() => {
    const reportTimezone = timezone ? timezone.name : undefined;
    _.forEach(sqReportStore.content, ({ id, timezone: contentTimezone }) => {
      if (contentTimezone !== reportTimezone) {
        flux.dispatch('REPORT_UPDATE_CONTENT_TIMEZONE', {
          contentId: id,
          timezone: reportTimezone,
        });
        replaceContentIfExists(id);
      }
    });
  });
}

/**
 * Updates or adds Seeq content to the backend and caches it in the report store
 *
 * @param {Object} content - Content to add/update.
 * @param {boolean} noEmit - when true, no ‘content changed’ message is emitted to other viewers of this report
 * @param {String} [content.id] - ID of content; missing if content is new
 * @returns {Promise} that resolves with the ID of content that was created/updated
 */
export function saveContent(content: Content, noEmit = false, isDashboard = false): Promise<Content> {
  let result;
  let wasReact;
  const contentInput = formatContentToApiInput(content);
  const worksheetId = sqWorkbenchStore.stateParams.worksheetId;
  if (!content.id) {
    wasReact = content.isReact;
    result = sqContentApi.createContent(contentInput);
  } else {
    wasReact = sqReportStore.getContentById(content.id).isReact;
    // Whenever we update content, clear the cached images for that content so that we can give users a way to
    // ensure that their images are as up-to-date as possible (and also to match existing behavior).
    result = sqContentApi.updateContent(contentInput, {
      id: content.id,
      clearCache: true,
    });
  }

  return result.then(({ data }) => {
    const content = formatContentFromApiOutput(data);
    setContent(content);
    if (!isDashboard) {
      maybeClearVisualizationSpecificState(content.id, wasReact, content.isReact);
      clearPropertyOverridesForContent(content.id);

      if (!noEmit)
        emitReportWithSpecificUpdates(worksheetId, {
          contentIds: { updated: [content.id] },
        });
    }
    return content;
  });
}

/**
 * Updates or adds the Seeq content to the report store
 *
 * @param content - Content to add/update
 */
export function setContent(content: Content) {
  flux.dispatch('REPORT_SET_CONTENT', content, PUSH_IGNORE);
}

/**
 * Updates or adds frontend specific metadata for the given piece of content to the report store.
 *
 * @param displayMetadata - the display metadata
 */
export function setContentDisplayMetadata(displayMetadata: ContentDisplayMetadata) {
  flux.dispatch('REPORT_SET_CONTENT_DISPLAY_METADATA', displayMetadata, PUSH_IGNORE);
}

export function setReportScheduleError(error: string, errorCode: number) {
  flux.dispatch('REPORT_SET_REPORT_SCHEDULE_ERROR', { error, errorCode }, PUSH_IGNORE);
}

/**
 * Removes the display metadata associated with the given piece of content, assuming it exists.
 */
export function removeContentDisplayMetadata(contentId: string) {
  flux.dispatch('REPORT_REMOVE_CONTENT_DISPLAY_METADATA', contentId, PUSH_IGNORE);
}

/**
 * Sets the hash code for the given piece of content.
 *
 * @param {string} contentId - ID of the content object to update
 * @param {string} hashCode - The unique identifier for the current variant of the image
 */
export function setContentHashCode(contentId, hashCode) {
  flux.dispatch('REPORT_SET_CONTENT_HASH_CODE', { contentId, hashCode }, PUSH_IGNORE);
}

export function setContentWarning(contentId: string, warning: string) {
  flux.dispatch('REPORT_SET_CONTENT_WARNING', { contentId, warning }, PUSH_IGNORE);
}

/**
 * Retrieves the content specified by the id, removes the Archived property (if present), and populates the store.
 * If using a dateRange, ensures that the dateRange is restored as well.
 *
 * @param {string} id - ID of content to restore
 * @returns {Promise} that resolves when the item has been restored
 */
export function restoreContent(id): Promise<any> {
  return exposedForTesting.fetchContent(id).then(({ content, dateRange }) => {
    const promises = [];
    if (content.isArchived) {
      promises.push(exposedForTesting.saveContent({ ...content, isArchived: false }));
    }
    if (dateRange?.isArchived) {
      promises.push(exposedForTesting.saveDateRange({ ...dateRange, isArchived: false }));
    }

    return Promise.all(promises);
  });
}

/**
 * Sets the archived property on a piece of Seeq content. Note that it does NOT remove any HTML
 * elements associated with the content from the document itself; this export function assumes that the elements have
 * already been removed. Also does not remove any link to a dateRange, so that operations such as Undo can restore
 * a piece of content.
 *
 * @param {Object} content - Content to remove
 * @param {String} content.id - ID of content to remove
 * @returns {Promise} that resolves when the Content has been removed
 */
export function removeContent(content: Content, isDashboard = false) {
  return exposedForTesting.saveContent({ ...content, isArchived: true }, false, isDashboard);
}

/**
 * Update the height and width of fixed-size content after the image has been rendered. Does not save the content
 * to the backend, since the backend representation doesn't need to be updated.
 *
 * @param {string} contentId - ID of content to update
 * @param {number} width - rendered width of content, in pixels
 * @param {number} height - rendered height of content, in pixels
 */
export function setContentRenderSize(contentId: string, width: number, height: number) {
  flux.dispatch('REPORT_SET_CONTENT_RENDER_SIZE', { contentId, width, height }, PUSH_IGNORE);
}

export function updateAssetSelection(selection: AssetSelection): Promise<AssetSelection> {
  const assetSelectionInput: AssetSelectionInputV1 = formatAssetSelectionToApiInput(selection);
  return sqContentApi
    .updateAssetSelection(assetSelectionInput, { id: selection.id })
    .then(({ data }) => formatAssetSelectionFromApiOutput(data));
}

/**
 * Create or update an asset selection and save it to the backend
 *
 * @param selection
 */
export function saveAssetSelection(selection: AssetSelection): Promise<string> {
  let result;
  const assetSelectionInput: AssetSelectionInputV1 = formatAssetSelectionToApiInput(selection);
  const isNew = !selection.id;
  const onlyNameChange = _.isEqual(
    _.omit(sqReportStore.getAssetSelectionById(selection.id), ['name']),
    _.omit(selection, ['name']),
  );
  const onlyDepthChange = _.isEqual(
    _.omit(sqReportStore.getAssetSelectionById(selection.id), ['assetPathDepth']),
    _.omit(selection, ['assetPathDepth']),
  );
  const worksheetId = sqReportStore.sandboxMode.enabled
    ? sqReportStore.sandboxMode.sandboxedWorksheetId
    : sqWorkbenchStore.stateParams.worksheetId;

  if (isNew) {
    result = sqContentApi.createAssetSelection(assetSelectionInput).then(({ data }) => {
      const assetSelection = formatAssetSelectionFromApiOutput(data);
      setAssetSelection(assetSelection);
      return assetSelection.id;
    });
  } else {
    result = exposedForTesting.updateAssetSelection(selection).then((assetSelection) => {
      setAssetSelection(assetSelection);
      if (onlyNameChange || onlyDepthChange) {
        // Dont re-render associated content, wait until a new change
        return selection.id;
      } else {
        forceRefreshContentUsingAssetSelection(assetSelection.id);
        return assetSelection.id;
      }
    });
  }
  return result
    .then((assetSelectionId) => {
      if (onlyNameChange || onlyDepthChange) {
        return assetSelectionId;
      }
      return (
        _.chain(sqReportStore.contentUsingAssetSelection(assetSelectionId))
          .filter((content) => !!content.dateRangeId)
          .map((content) => sqReportStore.getDateRangeById(content.dateRangeId))
          .filter((dateRange) => dateRange.condition?.id)
          .uniqBy('id')
          .map((dateRange) =>
            getDependencies({ id: dateRange.condition.id })
              // only add date ranges with a condition that has 1 asset dependency
              .then(({ assets }) => (assets.length !== 1 ? undefined : { ...dateRange, dateRangeAsset: assets[0] })),
          )
          .thru((promises) => Promise.all(promises))
          .value()
          .then(_.compact)
          // If one of the potential targets is already on the target asset, then we already have a one to one
          // match, no need to continue, return empty array
          .then((dateRanges) =>
            _.some(dateRanges, (dateRange) => dateRange.dateRangeAsset.id === selection.asset.id) ? [] : dateRanges,
          )
          .then((dateRanges) =>
            _.chain(dateRanges)
              .map((dateRange) =>
                sqItemsApi
                  .findSwap(
                    [
                      {
                        swapIn: selection.asset.id,
                        swapOut: dateRange.dateRangeAsset.id,
                      },
                    ],
                    { id: dateRange.condition.id },
                  )
                  .then(({ data: item }) => ({
                    ...dateRange,
                    swapItem: item,
                  })),
              )
              .thru((promises) => Promise.all(promises))
              .value(),
          )
          .then((dateRanges) => _.filter(dateRanges, (dateRange) => !!dateRange.swapItem))
          .then((dateRanges) => {
            if (dateRanges.length > 1) {
              if (exposedForTesting.isAdvancedDateRangeSwapEnabled()) {
                const potentialSwaps: DateRangeSwapInfo[] = _.map(dateRanges, (dateRange) => ({
                  dateRange,
                  swapItem: dateRange.swapItem,
                  swapAsset: dateRange.dateRangeAsset,
                  assetSelection: selection,
                }));
                exposedForTesting.setActiveDateRangeSwapInfo(undefined, potentialSwaps);
                exposedForTesting.setShowChooseAssetSwapModal(true);
              } else {
                doTrack('DateRange Asset Swap', 'Advanced Swap', 'Attempted');
                warnToast(
                  {
                    messageKey: 'REPORT.MODAL.DATE_RANGE_ASSET_SWAP.ADVANCED_NOT_SUPPORTED_MESSAGE',
                  },
                  { autoClose: 30_000 },
                );
              }
              return assetSelectionId;
            } else if (dateRanges.length === 0) {
              // do nothing
              return assetSelectionId;
            } else {
              // There is only one eligible date range
              const dateRange = dateRanges[0];
              exposedForTesting.setActiveDateRangeSwapInfo(
                {
                  dateRange: dateRange as DateRange,
                  swapItem: dateRange.swapItem,
                  swapAsset: dateRange.dateRangeAsset,
                  assetSelection: selection,
                },
                [],
              );
              exposedForTesting.setShowChooseCapsuleModal(true);
              return assetSelectionId;
            }
          })
          .then(() => {
            const property = isNew ? 'inserted' : 'updated';
            emitReportWithSpecificUpdates(worksheetId, {
              assetSelectionIds: { [property]: [assetSelectionId] },
            });
            return assetSelectionId;
          })
      );
    })
    .catch((err) => {
      errorToast({ httpResponseOrError: err });
      forceRefreshContentUsingAssetSelection(selection.id);
    });
}

/**
 * Removes the asset selection from the report. Content that uses the selection goes back to inheriting its asset from
 * the worksheet.
 *
 * @returns Promise that resolves once the selection has been archived
 */
export function removeAssetSelection(assetSelection: AssetSelection) {
  return exposedForTesting
    .saveAssetSelection({ ...assetSelection, isArchived: true })
    .then(() => {
      const contentToUpdate = sqReportStore.contentUsingAssetSelection(assetSelection.id);
      // Remove assetSelection from any content previously using it
      return _.chain(contentToUpdate)
        .map((content) => exposedForTesting.saveContent(_.omit(content, 'assetSelectionId'), true))
        .thru((p) => Promise.all(p))
        .value()
        .then(() => contentToUpdate);
    })
    .then((contentToUpdate) => _.forEach(contentToUpdate, (content) => replaceContentIfExists(content.id)));
}

export function updateDateRange(dateRange): Promise<DateRange> {
  const dateRangeInput: DateRangeInputV1 = formatDateRangeToApiInput(dateRange);
  return sqContentApi
    .updateDateRange(dateRangeInput, { id: dateRange.id })
    .then(({ data }) => formatDateRangeFromApiOutput(data));
}

/**
 * Adds or updates the dateRange on the backend and caches it in the report store. All content using the dateRange
 * is automatically updated either by stepping to now if it is an auto-updating date range or refreshing the
 * content if it is fixed.
 *
 * @param {Object} dateRange - dateRange to set
 * @returns {Promise} that resolves with the ID of the dateRange when it and any modified content has been saved
 */
export function saveDateRange(dateRange): Promise<string> {
  let result;
  const dateRangeInput: DateRangeInputV1 = formatDateRangeToApiInput(dateRange);
  const isNewDateRange = !dateRange.id;
  const worksheetId = sqReportStore.sandboxMode.enabled
    ? sqReportStore.sandboxMode.sandboxedWorksheetId
    : sqWorkbenchStore.stateParams.worksheetId;

  if (isNewDateRange) {
    result = sqContentApi.createDateRange(dateRangeInput).then(({ data }) => {
      const dateRange = formatDateRangeFromApiOutput(data);
      const dateRangeId = dateRange.id;
      setDateRange(dateRange);
      return dateRangeId;
    });
  } else {
    const onlyNameChange = _.isEqual(
      _.omit(sqReportStore.getDateRangeById(dateRange.id), ['name']),
      _.omit(dateRange, ['name']),
    );
    result = exposedForTesting.updateDateRange(dateRange).then((dateRange) => {
      setDateRange(dateRange);
      if (onlyNameChange) {
        // Dont re-render associated content, wait until a new change
        return dateRange.id;
      } else if (dateRange.auto.enabled && sqReportStore.hasReportSchedule && sqReportStore.isScheduleEnabled) {
        return exposedForTesting.stepScheduledReportToNow().then(() => dateRange.id);
      } else {
        forceRefreshContentUsingDate(dateRange.id);
        return dateRange.id;
      }
    });
  }

  return result
    .then((dateRangeId) => {
      const property = isNewDateRange ? 'inserted' : 'updated';
      emitReportWithSpecificUpdates(worksheetId, {
        dateRangeIds: { [property]: [dateRangeId] },
      });

      const maybeRemoveReportSchedulePromise =
        !sqReportStore.hasAutoDateRanges && sqReportStore.hasReportSchedule
          ? exposedForTesting.saveReportSchedule(undefined)
          : Promise.resolve({});
      return maybeRemoveReportSchedulePromise.then(() => dateRangeId);
    })
    .catch((err) => {
      errorToast({ httpResponseOrError: err });
      refreshContentUsingDate(dateRange.id);
    });
}

/**
 * Updates or adds the Seeq content to the report store
 *
 * @param {DateRange} dateRange - DateRange to add/update
 */
export function setDateRange(dateRange: DateRange) {
  flux.dispatch('REPORT_SET_DATE_RANGE', dateRange, PUSH_IGNORE);
}

/**
 * Removes the date range from the report. Content that uses the date range goes back to inheriting its date from
 * the worksheet.
 *
 * @param {Object} dateRange - The date variable to remove.
 * @param {String} dateRange.id - A unique identifier for the variable
 * @returns {Promise} that resolves when date range has been archived
 */
export function removeDateRange(dateRange) {
  const contentToUpdate = sqReportStore.contentUsingDateRange(dateRange.id);

  return (
    _.chain(contentToUpdate)
      .map((content) => exposedForTesting.saveContent(_.omit(content, 'dateRangeId'), true))
      .thru((p) => Promise.all(p))
      .value()
      .then(() => exposedForTesting.saveDateRange({ ...dateRange, isArchived: true }))
      // Remove dateRange from any content previously using it
      .then(() => _.forEach(contentToUpdate, (content) => replaceContentIfExists(content.id)))
  );
}

/**
 * Similar to sqDurationActions.*Range.stepToEnd(), this export function moves the date range contained within in the
 * date range variable such that the end is at the current time. It also takes care of updating the calculated
 * capsule if that capsule has changed
 *
 * @param {string} dateRangeId - ID of the dateRange to update
 * @returns {Promise} resolves when the date range has been updated
 */
export function stepDateRangeToEnd(dateRangeId: string) {
  const dateRange = sqReportStore.getDateRangeById(dateRangeId);
  if (!dateRange) {
    return Promise.reject(`Date range with id ${dateRangeId} not found.`);
  }
  if (dateRange.auto.enabled) {
    return Promise.reject(
      `Can't step individual date range, ${dateRange.name} (${dateRange.id}), to end because it has a live range`,
    );
  }
  if (!dateRange.range) {
    return Promise.reject(`Can't step date range, ${dateRange.name} (${dateRange.id}), to end because it has no range`);
  }

  const now = moment().utc().valueOf();

  if (!_.get(dateRange.condition, 'id', false)) {
    const duration = moment.duration(dateRange.range.end - dateRange.range.start);
    const range = {
      start: moment.utc(now).subtract(duration).valueOf(),
      end: moment.utc(now).valueOf(),
    };

    return exposedForTesting.saveDateRange({
      ...dateRange,
      range: {
        ...dateRange.range,
        ...range,
      },
    });
  }

  let duration;
  const searchRangeEnd = _.get(dateRange.condition, 'range.end');
  const searchRangeStart = _.get(dateRange.condition, 'range.start');
  if (searchRangeStart && searchRangeEnd) {
    duration = moment.duration(searchRangeEnd - searchRangeStart);
  } else {
    duration = moment.duration(dateRange.range.end - dateRange.range.start);
  }
  const range = {
    start: moment.utc(now).subtract(duration).valueOf(),
    end: moment.utc(now).valueOf(),
  };

  const offset = computeCapsuleOffset(dateRange.condition);
  return exposedForTesting.computeCapsule(dateRange.condition, range, offset).then((capsule) =>
    exposedForTesting.saveDateRange({
      ...dateRange,
      condition: {
        ...dateRange.condition,
        range,
      },
      range: {
        ...dateRange.range,
        start: capsule.start / NUMBER_CONVERSIONS.NANOSECONDS_PER_MILLISECOND,
        end: capsule.end / NUMBER_CONVERSIONS.NANOSECONDS_PER_MILLISECOND,
      },
    }),
  );
}

/**
 * Update `now` for a scheduled report (also stepping the scheduled date ranges to now)
 * @returns {Promise} resolves when the report has been updated
 */
export function stepScheduledReportToNow() {
  return _.chain(sqReportStore.dateRangesNotArchived)
    .filter('auto.enabled')
    .forEach((dateRange) => refreshContentUsingDate(dateRange.id))
    .thru((autoDateRanges) =>
      autoDateRanges.length
        ? exposedForTesting.updateReport(sqReportStore.id, getHtmlReportEditor(), false, true)
        : Promise.resolve({}),
    )
    .value();
}

/**
 * Determines how many capsule are present in a condition for the given time range.
 *
 * @param {Object} condition - The condition to evaluate
 * @param {String} condition.id - The id of the condition
 * @param {Range} range - The date range in which to find capsules
 * @return {Promise} Resolves with the count of capsules
 */
export function computeCapsuleCount(condition, range) {
  const { id } = condition;
  const start = moment.utc(range.start).toISOString();
  const end = moment.utc(range.end).toISOString();
  const formula = `$condition${getMaximumDurationFormula(condition)}.count(capsule('${start}','${end}'))`;
  const parameters = { condition: id };

  return computeScalar({ formula, parameters }).then(({ value }) => value);
}

/**
 * Determines the capsule to be used for a live screenshot given a condition and time range.
 *
 * @param {Object} condition - The condition to evaluate
 * @param {String} condition.id - The id of the condition
 * @param {Range} range - The date range in which to find capsules
 * @param {Number} offset - Which capsule to pick from the group
 *
 * @return {Promise} Resolves with the capsule or rejects if there are none
 */
export function computeCapsule(condition, range, offset) {
  const { id } = condition;
  const start = moment.utc(range.start).toISOString();
  const end = moment.utc(range.end).toISOString();
  const formula = `$condition.setCertain()${getMaximumDurationFormula(
    condition,
  )}.toGroup(capsule('${start}','${end}')).pick(${offset})`;

  const parameters = { condition: id };

  return computeCapsules({ formula, parameters }).then((response) =>
    _.size(response.capsules) > 0 ? response.capsules[0] : Promise.reject(),
  );
}

/**
 * Returns the formula segment for the maximum duration
 * This should be added for all unbounded conditions, as a max duration is required for topics
 *
 * @param {Object} condition - The condition housing the maximum duration data
 */
export function getMaximumDurationFormula(condition: any) {
  const hasMaxDuration = { ...condition.maximumDuration };
  return !_.isEmpty(hasMaxDuration) ? `.removeLongerThan(${hasMaxDuration.value}${hasMaxDuration.units})` : '';
}

/**
 * Save a new comment
 *
 * @param  {String} reportId - ID of the journal entry to which this is a comment
 * @param  {String} name - the text of the comment
 * @return {Promise} Promise which is resolved when the comment is saved
 */
export function addComment(reportId, name) {
  const worksheetId = sqWorkbenchStore.stateParams.worksheetId;
  return sqAnnotationsApi.createAnnotation({ repliesTo: reportId, name }).then(({ data: comment }) => {
    flux.dispatch('REPORT_SET_COMMENT', comment, PUSH_IGNORE);
    exposedForTesting.emitReportWithSpecificUpdates(worksheetId, {
      commentIds: { inserted: [comment.id] },
    });
  });
}

/**
 * Update an existing comment
 *
 * @param  {String} commentId - the ID of the comment to update
 * @param {String} name - the text of the comment
 * @return {Promise} Promise which is resolved when the comment is updated
 */
export function updateComment(commentId, name) {
  const worksheetId = sqWorkbenchStore.stateParams.worksheetId;
  return sqAnnotationsApi.updateAnnotation({ name }, { id: commentId }).then(({ data: comment }) => {
    flux.dispatch('REPORT_SET_COMMENT', comment, PUSH_IGNORE);
    exposedForTesting.emitReportWithSpecificUpdates(worksheetId, {
      commentIds: { updated: [commentId] },
    });
  });
}

/**
 * Delete an existing comment
 *
 * @param  {String} commentId - the ID of the comment to delete
 * @returns {Promise} a promise that resolves when comments have been retrieved and set in the store
 */
export function deleteComment(commentId) {
  const worksheetId = sqWorkbenchStore.stateParams.worksheetId;
  return sqAnnotationsApi.archiveAnnotation({ id: commentId }).then(() => {
    flux.dispatch('REPORT_REMOVE_COMMENT', commentId, PUSH_IGNORE);
    exposedForTesting.emitReportWithSpecificUpdates(worksheetId, {
      commentIds: { removed: [commentId] },
    });
  });
}

/**
 * Fetch the data for the report view. Used when rehydrating
 *
 * @returns {Promise} A promise that resolves when all the data is fetched.
 */
export function fetchReport() {
  if (!sqReportStore.id) {
    return Promise.resolve({});
  }

  return extraExposedForTesting.loadReport(sqReportStore.id);
}

/**
 * Fetch a comment for the report and sets it in the store
 *
 * @returns {Promise} A promise that resolves when all the data is fetched.
 */
export function fetchComment(id: string) {
  return sqAnnotationsApi.getAnnotation({ id }).then(({ data: comment }) => {
    if (comment.type !== 'Reply') throw new Error("'id' does not correspond to a comment");
    flux.dispatch('REPORT_SET_COMMENT', comment, PUSH_IGNORE);
  });
}

/**
 * Synchronizes store when other users remove date ranges
 *
 * @param {ReportUpdateMessageWithSpecificUpdates} message containing ids of changes
 * @return {Promise} that is immediately resolved
 */
export function onReportSyncStoreDateRangeRemoval(message: ReportUpdateMessageWithSpecificUpdates): Promise<any> {
  if (!message || !message.dateRangeIds?.removed) return Promise.resolve();
  const { dateRangeIds } = message;

  dateRangeIds.removed?.forEach?.((dateRangeId) => {
    // Remove date range from content prior to removing the date range
    const contentUsingDateRange = sqReportStore.contentUsingDateRange(dateRangeId);
    contentUsingDateRange.forEach((content) => {
      const contentWithoutDateRange = _.omit(content, 'dateRangeId');
      flux.dispatch('REPORT_SET_CONTENT', contentWithoutDateRange, PUSH_IGNORE);
      replaceContentIfExists(content.id);
    });
    flux.dispatch(
      'REPORT_SET_DATE_RANGE',
      { ...sqReportStore.getDateRangeById(dateRangeId), isArchived: true },
      PUSH_IGNORE,
    );
  });

  return Promise.resolve();
}

/**
 * Synchronizes store when other users add date ranges
 *
 * @param {ReportUpdateMessageWithSpecificUpdates} message containing ids of changes
 * @return {Promise} that is resolved when the dateRange has been fetched and any content updated in the store
 */
export function onReportSyncStoreDateRangeInsertion(message: ReportUpdateMessageWithSpecificUpdates): Promise<any> {
  // By the time we've received the notification, the date range and content relationships should be up to date on
  // the server.  So, we'll use that response to sync the content of our store.
  if (!message || !message.dateRangeIds?.inserted) return Promise.resolve();
  const { dateRangeIds } = message;

  return _.chain(dateRangeIds.inserted)
    .map((dateRangeId) => {
      return exposedForTesting.fetchDateRange(dateRangeId).then(({ contentIds: contentIdsUsingDateRange }) => {
        contentIdsUsingDateRange.forEach((contentId) => {
          const content = {
            ...sqReportStore.getContentById(contentId),
            dateRangeId,
          };
          flux.dispatch('REPORT_SET_CONTENT', content, PUSH_IGNORE);
        });
      });
    })
    .thru((p) => Promise.all(p))
    .value();
}

/**
 * Synchronizes store when other users update date ranges
 *
 * @param {ReportUpdateMessageWithSpecificUpdates} message containing ids of changes
 * @return {Promise} that is resolved when all specified date ranges have been fetched
 */
export function onReportSyncStoreDateRangeUpdate(message: ReportUpdateMessageWithSpecificUpdates): Promise<any> {
  // Date range updates are isolated. Content and report are not affected, so we only need to update the store's
  // date range
  if (!message || !message.dateRangeIds?.updated) return Promise.resolve();
  const { dateRangeIds } = message;

  return _.chain(dateRangeIds.updated)
    .map((dateRangeId) => exposedForTesting.fetchDateRange(dateRangeId))
    .thru((p) => Promise.all(p))
    .value();
}

/**
 * Synchronizes store when other users update content
 *
 * @param {ReportUpdateMessageWithSpecificUpdates} message containing ids of changes
 * @return {Promise} that is resolved when all content specified has been fetched
 */
export function onReportSyncStoreContentUpdate(message: ReportUpdateMessageWithSpecificUpdates): Promise<any> {
  if (!message || !message.contentIds?.updated) return Promise.resolve();
  const { contentIds } = message;
  return exposedForTesting.fetchMultipleContent(contentIds.updated);
}

/**
 * Synchronizes store when other users remove comments
 *
 * @param {ReportUpdateMessageWithSpecificUpdates} message containing ids of changes
 * @return {Promise} that is immediately resolved
 */
export function onReportSyncStoreCommentRemoval(message: ReportUpdateMessageWithSpecificUpdates): Promise<any> {
  if (!message || !message.commentIds?.removed) return Promise.resolve();
  const { commentIds } = message;
  commentIds.removed?.forEach?.((commentId) => flux.dispatch('REPORT_REMOVE_COMMENT', commentId, PUSH_IGNORE));
  return Promise.resolve();
}

/**
 * Synchronizes store when other users add comments
 *
 * @param {ReportUpdateMessageWithSpecificUpdates} message containing ids of changes
 * @return {Promise} Promise which is resolved when the comment is inserted
 */
export function onReportSyncStoreCommentInsertion(message: ReportUpdateMessageWithSpecificUpdates): Promise<any> {
  if (!message || !message.commentIds?.inserted) return Promise.resolve();
  const { commentIds } = message;
  return _.chain(commentIds.inserted)
    .map((commentId) => exposedForTesting.fetchComment(commentId))
    .thru((p) => Promise.all(p))
    .value();
}

/**
 * Synchronizes store when other users update comments
 *
 * @param {ReportUpdateMessageWithSpecificUpdates} message containing ids of changes
 * @return {Promise} Promise which is resolved when the comment is updated
 */
export function onReportSyncStoreCommentUpdate(message: ReportUpdateMessageWithSpecificUpdates): Promise<any> {
  if (!message || !message.commentIds?.updated) return Promise.resolve();
  const { commentIds } = message;
  return _.chain(commentIds.updated)
    .map((commentId) => exposedForTesting.fetchComment(commentId))
    .thru((p) => Promise.all(p))
    .value();
}

/**
 * Synchronizes store when other users add asset selections
 *
 * @param {ReportUpdateMessageWithSpecificUpdates} message containing ids of changes
 * @return {Promise} that is resolved when the asset selection has been fetched and any content updated in the store
 */
export function onReportSyncStoreAssetSelectionInsertion(
  message: ReportUpdateMessageWithSpecificUpdates,
): Promise<any> {
  // By the time we've received the notification, the asset selection and content relationships should be up to
  // date on the server.  So, we'll use that response to sync the content of our store.
  if (!message || !message.assetSelectionIds?.inserted) return Promise.resolve();
  const { assetSelectionIds } = message;

  return _.chain(assetSelectionIds.inserted)
    .map((assetSelectionId) =>
      exposedForTesting.fetchAssetSelection(assetSelectionId).then(({ contentIds: contentIdsUsingAssetSelections }) => {
        contentIdsUsingAssetSelections.forEach((contentId) => {
          const content = {
            ...sqReportStore.getContentById(contentId),
            assetSelectionId,
          };
          flux.dispatch('REPORT_SET_CONTENT', content, PUSH_IGNORE);
        });
      }),
    )
    .thru((p) => Promise.all(p))
    .value();
}

/**
 * Fetches a single asset selection used in the current report, populating in sqReportStore.
 *
 * @param  id - ID of selection to fetch
 * @param populateStore - true to populate fetched assetSelection in report store
 * @returns {Promise} that resolves when the asset selection has been retrieved.
 */
export function fetchAssetSelection(
  id: string,
  populateStore = true,
): Promise<{ assetSelection: AssetSelection; contentIds: string[] }> {
  return sqContentApi.getAssetSelection({ id }).then(({ data }) => {
    const assetSelection = formatAssetSelectionFromApiOutput(data);
    if (populateStore) {
      flux.dispatch('REPORT_SET_ASSET_SELECTION', assetSelection, PUSH_IGNORE);
    }
    return {
      assetSelection,
      contentIds: data?.content?.map?.((contentItemPreview) => contentItemPreview.id),
    };
  });
}

/**
 * Synchronizes store when other users update asset selections
 *
 * @param {ReportUpdateMessageWithSpecificUpdates} message containing ids of changes
 * @return {Promise} that is resolved when all specified selections have been fetched
 */
export function onReportSyncStoreAssetSelectionUpdate(message: ReportUpdateMessageWithSpecificUpdates): Promise<any> {
  if (!message || !message.assetSelectionIds?.updated) return Promise.resolve();
  const { assetSelectionIds } = message;

  return _.chain(assetSelectionIds.updated)
    .map((selectionId) => exposedForTesting.fetchAssetSelection(selectionId))
    .thru((p) => Promise.all(p))
    .value();
}

/**
 * Synchronizes the store by processing all of the received changes
 *
 * @param {ReportUpdateMessage} reportUpdateMessage containing type of message and possibly ids of changes
 * @return {Promise} that resolves when the associated actions have been completed
 */
export function onReportSyncStore(reportUpdateMessage: ReportUpdateMessage): Promise<ReportUpdateMessage> {
  // From the perspective of our store:
  //  Document Change: For now, we update everything in the store from the server since there's a lot to worry
  //  about -- comments, backups, etc, but we can get more selective as needed. An alternative is to pass the
  //  whole store from the sender and use that.  There is room for optimization by doing a smart diff of the
  //  document to determine what needs to be updated
  //
  //  Content or date range modifications: Update only what has changed
  //
  // From the perspective of listeners:
  //  There are components that listen to the store, and it may or may not make a significant
  //  difference to selectively set the store content since the listeners will get a notification
  //  for any change.

  const { type, message } = reportUpdateMessage;
  if (type === ReportUpdateMessageType.FULL_UPDATE) {
    return Promise.resolve(exposedForTesting.fetchReport()).then((_report) => reportUpdateMessage);
  } else if (type === ReportUpdateMessageType.SPECIFIC_UPDATES_ONLY) {
    if (!message) return Promise.resolve(reportUpdateMessage);
    return exposedForTesting
      .onReportSyncStoreDateRangeRemoval(message)
      .then(() => exposedForTesting.onReportSyncStoreDateRangeInsertion(message))
      .then(() => exposedForTesting.onReportSyncStoreDateRangeUpdate(message))
      .then(() => exposedForTesting.onReportSyncStoreContentUpdate(message))
      .then(() => exposedForTesting.onReportSyncStoreAssetSelectionInsertion(message))
      .then(() => exposedForTesting.onReportSyncStoreAssetSelectionUpdate(message))
      .then(() => exposedForTesting.onReportSyncStoreCommentRemoval(message))
      .then(() => exposedForTesting.onReportSyncStoreCommentInsertion(message))
      .then(() => exposedForTesting.onReportSyncStoreCommentUpdate(message))
      .then(() => reportUpdateMessage);
  } else {
    throw new Error('Invalid message type: Unable to parse the report update message');
  }
}

/**
 * Synchronizes the view by processing all of the received changes
 *
 * @param {ReportUpdateMessage} reportUpdateMessage containing type of message and possibly ids of changes
 */
export function onReportSyncView(reportUpdateMessage: ReportUpdateMessage) {
  const { type, message } = reportUpdateMessage;
  if (type === ReportUpdateMessageType.FULL_UPDATE) {
    const document = sqReportStore.document;
    exposedForTesting.setReportView(document);
  } else if (type === ReportUpdateMessageType.SPECIFIC_UPDATES_ONLY) {
    // Otherwise, refresh just the content that has been affected
    const { contentIds, dateRangeIds, assetSelectionIds } = message as ReportUpdateMessageWithSpecificUpdates;
    dateRangeIds?.inserted?.forEach?.((dateRangeId) => refreshContentUsingDate(dateRangeId));
    dateRangeIds?.updated?.forEach?.((dateRangeId) => refreshContentUsingDate(dateRangeId));
    assetSelectionIds?.inserted?.forEach((assetSelectionId) =>
      forceRefreshContentUsingAssetSelection(assetSelectionId),
    );
    assetSelectionIds?.updated?.forEach((assetSelectionId) => forceRefreshContentUsingAssetSelection(assetSelectionId));
    contentIds?.updated?.forEach?.((contentId) => replaceContentIfExists(contentId));
  } else {
    throw new Error('Invalid message type: Unable to parse the report update message');
  }
}

/**
 * Handles report update messages.
 *
 * @param data the data describing the report changes
 * @returns {Promise} that resolves when report has been loaded
 */
export function onReport({ data }): Promise<any> {
  if (sqReportStore.sandboxMode.enabled) {
    return Promise.resolve()
      .then(() => exposedForTesting.onReportSyncStoreCommentRemoval(data.message))
      .then(() => exposedForTesting.onReportSyncStoreCommentInsertion(data.message))
      .then(() => exposedForTesting.onReportSyncStoreCommentUpdate(data.message));
  } else {
    return Promise.resolve()
      .then(() => exposedForTesting.onReportSyncStore(data))
      .then((reportUpdateMessage: ReportUpdateMessage) => exposedForTesting.onReportSyncView(reportUpdateMessage))
      .catch((err) => {
        // Log error and recover by reloading the report
        return exposedForTesting.fetchReport().then(() => {
          exposedForTesting.setReportView(sqReportStore.document);
          refreshAllContent();
        });
      });
  }
}

/**
 * Emits a report update message.
 */
export function emitReport(worksheetId: string) {
  emit([SeeqNames.Channels.ReportUpdateChannel, worksheetId], {
    type: ReportUpdateMessageType.FULL_UPDATE,
  });
}

/**
 * Sets the report view to the specified document
 *
 * @param {string} document - updated html document received
 */
export function setReportView(document) {
  if (canModifyDocument()) {
    const scrollOffset = getScrollOffset();
    const maybePosition = getCursorPosition('reportEditor');
    setHtmlCKEditor(document, 'reportEditor');
    setScrollOffset(scrollOffset);
    maybePosition && setCursorPosition(maybePosition, 'reportEditor');
  } else {
    setHtmlCKEditor(document, 'reportEditor');
  }
}

/**
 * Emits a report update message.
 */
export function emitReportWithSpecificUpdates(
  worksheetId: string,
  reportUpdateMessage: ReportUpdateMessageWithSpecificUpdates,
) {
  emit([SeeqNames.Channels.ReportUpdateChannel, worksheetId], {
    type: ReportUpdateMessageType.SPECIFIC_UPDATES_ONLY,
    message: reportUpdateMessage,
  });
}

/**
 * Fetches the backup document property and sets it as the backupPreview property in the report store.
 *
 * @param {Object} backupPreview - object container for backup preview
 * @param {string} backupPreview.backupName - the backup preview property name
 */
export function setBackupPreview(backupPreview) {
  sqItemsApi
    .getProperty({
      id: sqReportStore.id,
      propertyName: backupPreview.backupName,
    })
    .then(({ data }) => {
      flux.dispatch(
        'REPORT_SET_BACKUP_PREVIEW',
        {
          backupPreview: _.merge({}, backupPreview, {
            document: _.get(data, 'value'),
          }),
        },
        PUSH_IGNORE,
      );
    });
}

/**
 * Clears the report store backupPreview property
 */
export function clearBackupPreview() {
  flux.dispatch('REPORT_SET_BACKUP_PREVIEW', {}, PUSH_IGNORE);
}

/**
 * Archives content and date ranges that are not in the backup, and unarchives content and date ranges that are
 * part of the backup. Also updates the store
 *
 * NOTE: There is a known deficiency where the date range may not be restored if the content has a history of
 * having its associated date range changed
 *
 * @param {string} backupDocument - HTML document contents to parse
 * @returns {Promise} A promise that resolves after all server calls are made
 */
export function syncServerAndStoreToBackup(backupDocument): Promise<any> {
  // Ensure that all content in backup is restored
  const backupContentIds = exposedForTesting.parseSeeqContentIdsFromHtml(backupDocument);
  const currentContentIds = exposedForTesting.parseSeeqContentIdsFromHtml(sqReportStore.document);

  const contentIdsToRemove = _.difference(currentContentIds, backupContentIds);
  const contentIdsToRestore = _.difference(backupContentIds, currentContentIds);

  return _.chain(contentIdsToRemove)
    .map((contentId) => exposedForTesting.removeContent(sqReportStore.getContentById(contentId)))
    .thru((p) => Promise.all(p))
    .value()
    .then(() =>
      _.chain(contentIdsToRestore)
        .map((contentId) => exposedForTesting.restoreContent(contentId))
        .thru((p) => Promise.all(p))
        .value(),
    );
}

/**
 * If the report store backupPreview property is defined, then tell the backend to restore the report annotation to
 * match the state of the backupName backup and set it as the current document.
 */
export function restoreBackup() {
  const { backupPreview } = sqReportStore;
  if (!backupPreview) return;

  const workbookId = sqWorkbenchStore.stateParams.workbookId;
  const worksheetId = sqWorkbenchStore.stateParams.worksheetId;
  const backupPreviewDocument = backupPreview.document;
  const { description, name } = nameAndDescriptionFromDocument(backupPreviewDocument);

  return exposedForTesting
    .syncServerAndStoreToBackup(backupPreviewDocument)
    .then(() =>
      sqAnnotationsApi.updateAnnotation(
        {
          name,
          description,
          type: API_TYPES.REPORT,
          backupName: backupPreview.backupName,
          reportInput: {
            cronSchedule: sqReportStore.reportSchedule?.cronSchedule,
            background: !!sqReportStore.reportSchedule?.background,
            enabled: !!sqReportStore.reportSchedule?.enabled,
          },
        },
        { id: sqReportStore.id },
      ),
    )
    .then(() => {
      // Clear the backup preview so the report editor will be displayed by ng-if
      exposedForTesting.clearBackupPreview();

      // Give the report editor time to be displayed in the DOM before restoring the backup
      setTimeout(() => {
        // Ensure the current version is in the undo buffer before setting the restored version
        setHtmlCKEditor(sqReportStore.document, 'reportEditor');
        setHtmlCKEditor(backupPreviewDocument, 'reportEditor');
        exposedForTesting.emitReport(worksheetId);
        generate({ workbookId, worksheetId, defer: true, viewKey: 'TOPIC', workstepId: sqWorkstepsStore.current.id });
      }, NG_IF_WAIT);
    });
}

/**
 * Set whether or not we're in the process of updating date ranges
 *
 * @param dateRangeUpdating - true if a date range is being updated, false otherwise
 */
export function setDateRangeUpdating(dateRangeUpdating: boolean) {
  flux.dispatch('REPORT_SET_DATE_RANGE_UPDATING', { dateRangeUpdating });
}

/**
 * Update the start and end times for the given date range.
 * @param {String} dateRangeId
 * @param {Moment} start
 * @param {Moment} end
 */
export function updateDateRangeStartAndEnd(dateRangeId, start, end) {
  flux.dispatch('REPORT_UPDATE_RANGE_START_AND_END', {
    dateRangeId,
    start,
    end,
  });
}

/**
 * Sets the given date range's "no capsule found" flag to the desired value (true, by default)
 * @param {String} dateRangeId
 * @param {Boolean} value
 */
export function setNoCapsuleFound(dateRangeId, value = true) {
  flux.dispatch('REPORT_UPDATE_NO_CAPSULE_FOUND', { dateRangeId, value });
}

/**
 * Sets the display mode for the bulk edit modal (INIT, ASSET_SELECTION, DATE_RANGE, or ERROR)
 * @param {BulkEditMode} bulkEditDisplayMode
 */
export function setBulkEditDisplayMode(bulkEditDisplayMode) {
  flux.dispatch('REPORT_SET_BULK_EDIT_DISPLAY_MODE', { bulkEditDisplayMode }, PUSH_IGNORE);
}

/**
 * Sets the interactive status to use for bulk editing
 * @param {Object} bulkInteractive
 */
export function setBulkInteractive(bulkInteractive: InteractiveReportContent) {
  flux.dispatch('REPORT_SET_BULK_INTERACTIVE', { bulkInteractive }, PUSH_IGNORE);
}

/**
 * Sets the shape to use for bulk editing
 * @param {Object} bulkShape
 */
export function setBulkShape(bulkShape) {
  flux.dispatch('REPORT_SET_BULK_SHAPE', { bulkShape }, PUSH_IGNORE);
}

/**
 * Sets the scale to use for bulk editing
 * @param {Object} bulkScale
 */
export function setBulkScale(bulkScale) {
  flux.dispatch('REPORT_SET_BULK_SCALE', { bulkScale }, PUSH_IGNORE);
}

/**
 * Sets the size to use for bulk editing
 * @param {Object} bulkSize
 */
export function setBulkSize(bulkSize) {
  flux.dispatch('REPORT_SET_BULK_SIZE', { bulkSize }, PUSH_IGNORE);
}

/**
 * Sets the width to use for bulk editing
 * @param {Object} bulkWidth
 */
export function setBulkWidth(bulkWidth) {
  flux.dispatch('REPORT_SET_BULK_WIDTH', { bulkWidth }, PUSH_IGNORE);
}

/**
 * Sets the height to use for bulk editing
 * @param {Object} bulkHeight
 */
export function setBulkHeight(bulkHeight) {
  flux.dispatch('REPORT_SET_BULK_HEIGHT', { bulkHeight }, PUSH_IGNORE);
}

/**
 * Sets the date range to use for bulk editing
 * @param {Object} bulkDateRange
 */
export function setBulkDateRange(bulkDateRange) {
  flux.dispatch('REPORT_SET_BULK_DATE_RANGE', { bulkDateRange }, PUSH_IGNORE);
}

/**
 * Sets the summary to use for bulk editing
 * @param bulkSummary
 */
export function setBulkSummary(bulkSummary: ReportContentSummary) {
  flux.dispatch('REPORT_SET_BULK_SUMMARY', { bulkSummary }, PUSH_IGNORE);
}

/**
 * Sets if uncertainty should be hidden for bulk editing
 * @param bulkHideUncertainty
 */
export function setBulkHideUncertainty(bulkHideUncertainty: boolean | undefined | null) {
  flux.dispatch('REPORT_SET_BULK_HIDE_UNCERTAINTY', { bulkHideUncertainty }, PUSH_IGNORE);
}

/**
 * Sets the AssetSelection to use for bulk edit
 * @param bulkAssetSelection
 */
export function setBulkAssetSelection(bulkAssetSelection?: AssetSelection) {
  flux.dispatch('REPORT_SET_BULK_ASSET_SELECTION', { bulkAssetSelection }, PUSH_IGNORE);
}

/**
 * Sets the content selected for bulk editing
 * @param {Object[]} selectedBulkContent
 */
export function setSelectedBulkContent(selectedBulkContent) {
  flux.dispatch('REPORT_SET_SELECTED_BULK_CONTENT', { selectedBulkContent }, PUSH_IGNORE);
}

/**
 * Sets whether or not all pieces of content being edited should update their workstep.
 */
export function setShouldUpdateBulkWorkstep(shouldUpdateBulkWorkstep: boolean) {
  flux.dispatch('REPORT_SET_SHOULD_UPDATE_BULK_WORKSTEP', { shouldUpdateBulkWorkstep }, PUSH_IGNORE);
}

/**
 * Clears all bulk properties back to default state
 */
export function clearBulkProperties() {
  flux.dispatch('REPORT_UPDATE_CLEAR_BULK_PROPERTIES', {}, PUSH_IGNORE);
}

/**
 * Marks the passed in content as selected if it is not selected, and does the opposite if it is selected
 *
 * @param {object} content
 */
export function toggleSpecificSelectedContent(content) {
  flux.dispatch('REPORT_TOGGLE_SPECIFIC_SELECTED_CONTENT', content, PUSH_IGNORE);
}

/**
 * Saves schedule on the report, overriding any schedule specified on individual date ranges in this report
 *
 * @returns {Promise} a promise that resolves when the report schedule has been saved
 */
export function saveReportSchedule(reportSchedule: ReportSchedule | undefined): Promise<any> {
  flux.dispatch('REPORT_SET_REPORT_SCHEDULE', reportSchedule, PUSH_IGNORE);
  return updateReport(sqReportStore.id);
}

/**
 * Updates the timestamp for the last time the report was updated due to a scheduled update
 */
export function incrementScheduledUpdateCount(): void {
  flux.dispatch('REPORT_SCHEDULED_UPDATE_RECEIVED', undefined, PUSH_IGNORE);
}

/**
 * Updates the next scheduled run time
 */
export function updateNextRunTime(reportId): Promise<string | undefined> {
  return sqAnnotationsApi
    .getAnnotation({ id: reportId })
    .then(({ data }) => data?.nextRunTime)
    .then((nextRunTime) => {
      flux.dispatch('REPORT_SET_NEXT_RUN_TIME', nextRunTime, PUSH_IGNORE);
      return nextRunTime;
    });
}

export function setShouldShowConfigureAutoUpdateModal(shouldShowConfigureAutoUpdateModal: boolean) {
  flux.dispatch('REPORT_SET_SHOULD_SHOW_CONFIGURE_AUTO_UPDATE_MODAL', {
    shouldShowConfigureAutoUpdateModal,
  });
}

/**
 * Set whether or not the auto-update modal should be visible
 *
 * @param showConfigureAutoUpdateModal - true to show the auto update modal
 * @param reportScheduleOverride
 */
export function setShowConfigureAutoUpdateModal(showConfigureAutoUpdateModal: boolean, reportScheduleOverride = false) {
  flux.dispatch('REPORT_SET_SHOW_CONFIGURE_AUTO_UPDATE_MODAL', {
    showConfigureAutoUpdateModal,
    reportScheduleOverride,
  });
}

export function setShowChooseAssetSwapModal(showModal: boolean) {
  flux.dispatch('REPORT_SET_SHOW_ASSET_SWAP_MODAL', showModal);
}

export function setShowChooseCapsuleModal(showModal: boolean) {
  flux.dispatch('REPORT_SET_SHOW_CAPSULE_MODAL', showModal);
}

export function setActiveDateRangeSwapInfo(currentSwap: DateRangeSwapInfo, potentialSwaps: DateRangeSwapInfo[]) {
  flux.dispatch('REPORT_SET_SWAP_RANGE_INFO', {
    currentSwap,
    potentialSwaps,
  });
}

export function setAssetSelection(selection: AssetSelection): void {
  flux.dispatch('REPORT_SET_ASSET_SELECTION', selection);
}

export function setAllAssetSelections(selections: AssetSelection[]): void {
  flux.dispatch('REPORT_SET_ALL_ASSET_SELECTIONS', selections);
}

export function setSandboxMode(sandboxMode: SandboxMode): void {
  flux.dispatch('REPORT_SET_SANDBOX_MODE', sandboxMode);
}

/**
 * Toggles whether or not the current report has a fixed width
 */
export function toggleFixedWidth() {
  sqItemsApi.setProperty(
    { value: !sqReportStore.isFixedWidth },
    { id: sqReportStore.id, propertyName: SeeqNames.Properties.IsFixedWith },
    { ignoreLoadingBar: true },
  );
  flux.dispatch('REPORT_SET_IS_FIXED_WIDTH', {
    isFixedWidth: !sqReportStore.isFixedWidth,
  });
}

/**
 * Decorates a export function that is passed in so that the return export function will activate sandbox mode instead
 * of executing the action the was passed in.
 *
 * @param action - A export function that operates on report content or dates or assetSelections, and which might
 * need to load sandbox mode prior to firing.
 * @param onAction - a export function that will fire BEFORE calling the action function. Can be a noop.
 * @param onSandboxLoadCompletion - A export function that will be called AFTER sandbox mode is loaded. Useful for
 * signaling the original caller, that this export function can be re-called to execute the action.
 */
export function doActionElseActivateSandbox(
  action: (...args: any[] | null) => any,
  onAction: (...args: any[] | null) => any,
  onSandboxLoadCompletion: (...args: any[] | null) => any,
  transformDataOnLoad: (data: any[]) => any = (d) => Promise.resolve(false),
) {
  return (...actionArgs: any[] | null) => {
    if (isViewOnlyWorkbookMode() && !sqReportStore.sandboxMode?.enabled) {
      return exposedForTesting
        .createSandboxAndLoadTempReport(transformDataOnLoad)
        .then(() => onSandboxLoadCompletion(...actionArgs));
    } else {
      onAction(...actionArgs);
      return action(...actionArgs);
    }
  };
}

/**
 * This export function will duplicate the current worksheet to a new Topic, which will be in the users home folder, but
 * archived. This duplicated version will be opened in sandbox mode
 */
export function createSandboxAndLoadTempReport(transformDataOnLoad = null): Promise<void> {
  if (loadingSandboxModePromise) {
    return loadingSandboxModePromise;
  }
  const isReportScheduleActive = sqReportStore.reportSchedule?.enabled;
  const sandboxOriginalCreatorName = sqReportStore.createdBy.name;
  doTrack('Sandbox Mode', 'Activate Sandbox Mode');
  const originalWorksheetId = sqWorkbenchStore.stateParams.worksheetId;
  const oldWorksheetName = sqWorkbookStore.getWorksheetName(originalWorksheetId);
  loadingSandboxModePromise = sqWorkbooksApi
    .createWorkbook({
      name: t('SANDBOX_MODE.VIEW_ONLY_TOPIC_NAME', { workbook: sqWorkbookStore.name }),
      folderId: 'mine',
      type: SeeqNames.Types.Topic,
      ownerId: sqWorkbenchStore.currentUser.id,
    })
    .then(async (newWorkbookResponse) => {
      await sqItemsApi.archiveItem({ id: newWorkbookResponse.data.id, archivedReason: 'BY_SANDBOX_MODE' });
      await setWorkBook(newWorkbookResponse.data.id, DEFAULT_WORKBOOK_STATE);
      const newWorksheetResponse = await sqWorkbooksApi.createWorksheet(
        { branchFrom: originalWorksheetId, name: oldWorksheetName },
        { workbookId: newWorkbookResponse.data.id },
      );
      await extraExposedForTesting.loadReport(newWorksheetResponse.data.report.id, transformDataOnLoad);
      setReportView(sqReportStore.document);
      if (isReportScheduleActive) {
        const reportSchedule = {
          ...sqReportStore.reportSchedule,
          enabled: true,
        };
        flux.dispatch('REPORT_SET_REPORT_SCHEDULE', reportSchedule);
        // When a dateRange is copied on the backend, enabled is set to false to prevent errant jobs running.
        // Here we need to determine which dateRanges are auto-updating, and re-enable them.
        const dateRangeUpdatePromises = _.chain(sqReportStore.dateRanges)
          .filter((dr) => dr.auto.enabled)
          .map((dr) => updateDateRange({ ...dr, enabled: true }))
          .value();
        await Promise.all(dateRangeUpdatePromises);
        await exposedForTesting.stepScheduledReportToNow();
      }
      setSandboxMode({
        enabled: true,
        originalWorksheetId,
        sandboxedWorkbookId: newWorkbookResponse.data.id,
        sandboxedWorksheetId: newWorksheetResponse.data.id,
        sandboxOriginalCreatorName,
      });
      subscribeToReport(newWorksheetResponse.data.report.id);
    })
    .finally(() => {
      loadingSandboxModePromise = undefined;
    });
  return loadingSandboxModePromise;
}

export function addContentError(contentId: string) {
  flux.dispatch('REPORT_ADD_CONTENT_ERROR', { contentId });
}

export function resetContentErrors() {
  flux.dispatch('REPORT_RESET_CONTENT_ERRORS');
}

/**
 * Turns the warning for a piece of content on or off
 *
 * @param contentId - If falsy, all content in this worksheet will have showWarningMessage updated
 * @param showWarningMessage - If true, warning message is shown for specified piece of content. If not, the warning
 * message is not shown.
 */
export function setContentShowWarningMessage(contentId: string, showWarningMessage: boolean) {
  flux.dispatch('REPORT_SET_CONTENT_SHOW_WARNING_MESSAGE', {
    contentId,
    showWarningMessage,
  });
}

export async function setPageLayout(layout: DocumentLayout) {
  await updatePDFSettingAndDispatch('REPORT_SET_LAYOUT', SeeqNames.Properties.Orientation, layout);
}

export async function setPaperSize(paperSize: DocumentPaperSize) {
  await updatePDFSettingAndDispatch('REPORT_SET_PAPER_SIZE', SeeqNames.Properties.PageSize, paperSize);
}

export async function setTopMargin(value: number | string, unit: DocumentMarginUnits) {
  await updatePDFSettingAndDispatch('REPORT_SET_MARGIN_TOP', SeeqNames.Properties.MarginTop, {
    value,
    units: unit,
  } as Margin);
}

export async function setBottomMargin(value: number | string, unit: DocumentMarginUnits) {
  await updatePDFSettingAndDispatch('REPORT_SET_MARGIN_BOTTOM', SeeqNames.Properties.MarginBottom, {
    value,
    units: unit,
  } as Margin);
}

export async function setLeftMargin(value: number | string, unit: DocumentMarginUnits) {
  await updatePDFSettingAndDispatch('REPORT_SET_MARGIN_LEFT', SeeqNames.Properties.MarginLeft, {
    value,
    units: unit,
  } as Margin);
}

export async function setRightMargin(value: number | string, unit: DocumentMarginUnits) {
  await updatePDFSettingAndDispatch('REPORT_SET_MARGIN_RIGHT', SeeqNames.Properties.MarginRight, {
    value,
    units: unit,
  } as Margin);
}

function validatePageMargin(value: number | string, unit: DocumentMarginUnits) {
  const num = _.isString(value) ? parseFloat(value) : value;
  if (!_.isFinite(num) || num < 0) {
    throw new TypeError(formatMessage`'${value}' is not a valid margin value`);
  }
  const margin = value + unit;
  return {
    stringValue: margin,
    margin: { value: num, units: unit } as Margin,
  };
}

function isMargin(potentialMargin: Margin | DocumentPaperSize | DocumentLayout): potentialMargin is Margin {
  return !_.isUndefined((potentialMargin as Margin).units);
}

async function updatePDFSettingAndDispatch(
  dispatchEvent: string,
  propertyName: string,
  pageSetting: Margin | DocumentPaperSize | DocumentLayout,
) {
  let dispatchValue: Margin | DocumentPaperSize | DocumentLayout = pageSetting;
  let propertyValue: string;

  if (isMargin(pageSetting)) {
    const { stringValue, margin } = validatePageMargin(pageSetting.value, pageSetting.units);
    dispatchValue = margin;
    propertyValue = stringValue;
  } else {
    propertyValue = pageSetting.value;
  }
  try {
    await sqItemsApi.setProperty(
      { value: propertyValue },
      { id: sqReportStore.id, propertyName },
      { ignoreLoadingBar: true },
    );
  } catch (e) {
    errorToast({ httpResponseOrError: e });
  }
  flux.dispatch(dispatchEvent, dispatchValue);
}

/** this function is used to set the layout to Landscape for Dashboards
 * It does NOT trigger a PDF creation request and is done every time a Dashboard is opened.
 */
export function setDefaultPageLayout(layout: DocumentLayout) {
  flux.dispatch('REPORT_SET_LAYOUT', layout);
}

export const exposedForTesting = {
  saveReportSchedule,
  updateAssetSelection,
  createSandboxAndLoadTempReport,
  updateReport,
  emitReport,
  emitReportWithSpecificUpdates,
  removeContent,
  restoreContent,
  parseSeeqContentIdsFromHtml,
  fetchContent,
  saveContent,
  saveDateRange,
  setActiveDateRangeSwapInfo,
  setShowChooseCapsuleModal,
  setShowChooseAssetSwapModal,
  isAdvancedDateRangeSwapEnabled,
  saveAssetSelection,
  stepScheduledReportToNow,
  computeCapsule,
  onReportSyncStoreCommentRemoval,
  onReportSyncStoreCommentInsertion,
  onReportSyncStoreCommentUpdate,
  onReportSyncStore,
  onReportSyncView,
  fetchReport,
  setReportView,
  fetchDateRange,
  fetchAssetSelection,
  fetchMultipleContent,
  fetchComment,
  onReportSyncStoreDateRangeRemoval,
  onReportSyncStoreDateRangeInsertion,
  onReportSyncStoreDateRangeUpdate,
  onReportSyncStoreContentUpdate,
  onReportSyncStoreAssetSelectionInsertion,
  onReportSyncStoreAssetSelectionUpdate,
  clearBackupPreview,
  syncServerAndStoreToBackup,
  onEditingStateEvent,
  updateNextRunTime,
  setSandboxMode,
  updateDateRange,
  setDateRange,
  setBulkSize,
};
export const extraExposedForTesting = {
  loadReport,
  setContentHashCode,
};
export const viewExposedForTesting = {
  setReportView,
};

export const throttledUpdateNextRunTime = _.throttle(exposedForTesting.updateNextRunTime, 5000);

// @ts-strict-ignore
import _ from 'lodash';

function intelligentlyTruncate(name: string, maxLength: number, separator = '...') {
  if (_.size(name) <= maxLength) {
    return name;
  }
  const correctMaxLength = maxLength - _.size(separator);
  const beforeCharCount = (correctMaxLength / 3) * 2;
  const endCharCount = correctMaxLength / 3;
  const totalCharCount = _.size(name);
  return `${name.substring(0, beforeCharCount)}${separator}${name.substring(
    totalCharCount - endCharCount,
    totalCharCount,
  )}`;
}

/**
 * Returns an object with { key: value } properties from a string split by ':' using the "key:value" format
 * Example:
 * ['key1:val1', 'key2:val2', 'invalid']
 * will result in
 * { key1: 'val1', key2: 'val2' }
 *
 * @param {string[]} paramValues
 * @returns
 */
function splitParamToObjectValue(paramValues) {
  return _.chain(paramValues)
    .filter((item) => item.indexOf(':') > -1)
    .map((item) => ({
      key: String(item).substring(0, String(item).indexOf(':')),
      value: String(item).substring(String(item).indexOf(':') + 1),
    }))
    .keyBy('key')
    .mapValues('value')
    .value();
}

/**
 * Sometimes we need to copy paste something different that what's displayed. By using a hidden input we can use this
 * function to copy that content
 * @param {string} text
 */
function copyTextToClipboard(text) {
  const element = document.createElement('textarea');
  element.innerHTML = text;
  element.setAttribute('readonly', '');
  document.body.appendChild(element);
  element.select();
  document.execCommand('copy');
  document.body.removeChild(element);
}

interface PropertyAndCallback {
  property: string | string[];
  callback: () => void;
}

/**
 * Calls the callback associated with a given property if said property has changed between oldObj and newObj
 *
 * @param oldObj - The old object maybe containing a property
 * @param newObj - The new object maybe containing a property
 * @param callbacksList - The list of properties and associated callbacks
 */
export function notifyIfChanged(oldObj: any, newObj: any, callbacksList: PropertyAndCallback[]) {
  _.forEach(callbacksList, (callbackItem) => {
    if (_.isArray(callbackItem.property)) {
      if (_.some(callbackItem.property, (property) => _.get(oldObj, property) !== _.get(newObj, property))) {
        callbackItem.callback();
      }
    } else if (_.get(oldObj, callbackItem.property) !== _.get(newObj, callbackItem.property)) {
      callbackItem.callback();
    }
  });
}

/**
 * Determines if the app is being driven by Playwright or Protractor
 */
export function isSystemTest(): boolean {
  return window.navigator.userAgent.includes('Playwright') || isProtractor();
}

// @deprecated Use isSystemTest instead
export function isProtractor(): boolean {
  return window.navigator.userAgent.includes('Protractor');
}

/**
 * A helper to throw errors in null chains. Without this, we have to define an anonymous function per call, such as
 * stringValue.match(/[^\/]+$/)?.[0] ?? (() => { throw new Error('Sample error message') })();
 * which gets shortened to stringValue.match(/[^\/]+$/)?.[0] ?? throwError('Sample error message');
 */
export function throwError(error: string): never {
  throw new Error(error);
}

export default {
  intelligentlyTruncate,
  splitParamToObjectValue,
  copyTextToClipboard,
};

export function getMaxSeriesPixels() {
  if (isProtractor()) {
    return 100;
  } else if (isSystemTest()) {
    return 500; // To allow for visualizing spikes in downsampnled data
  } else {
    return 5000;
  }
}

// @ts-strict-ignore
import React from 'react';
import _ from 'lodash';
import { addAssetOrItem, setIsLoading } from '@/assetGroupEditor/assetGroup.actions';
import { CREATED_BY_SEEQ_WORKBENCH } from '@/main/app.constants';
import { IconWithManagedSpinner } from '@/core/IconWithManagedSpinner.atom';
import { useFluxPath } from '@/core/hooks/useFluxPath.hook';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { isAsset, isTableDefinition } from '@/utilities/utilities';
import {
  sqAssetGroupStore,
  sqLicenseManagementStore,
  sqTableDefinitionStore,
  sqWorksheetStore,
} from '@/core/core.stores';
import { editAssetGroup, validateItemToAdd } from '@/assetGroupEditor/assetGroup.utilities';
import { canWriteItem } from '@/services/authorization.service';
import { isProtractor } from '@/core/utilities';
import { setDisplayAssetGroupEditor } from '@/worksheet/worksheet.actions';
import { TABLE_DEFINITION_VIEW } from '@/worksheet/worksheet.constants';
import { editTableDefinition } from '@/tableDefinitionEditor/tableDefinition.utilities';

interface SearchResultAssetIconProps {
  item: any;
  isAssetGroupView: boolean;
}

export const SearchResultAssetIcons: React.FunctionComponent<SearchResultAssetIconProps> = ({
  item,
  isAssetGroupView,
}) => {
  const storeAssets = useFluxPath(sqAssetGroupStore, () => sqAssetGroupStore.assets);
  const isTableDefinitionView = useFluxPath(
    sqWorksheetStore,
    () => sqWorksheetStore.view.key === TABLE_DEFINITION_VIEW.key,
  );
  const isEditingAssetGroup = useFluxPath(sqAssetGroupStore, () => sqAssetGroupStore.id === item.id);
  const isEditingTableDefinition =
    useFluxPath(sqTableDefinitionStore, () => sqTableDefinitionStore.id === item.id) && isTableDefinitionView;
  const isEditTableDefinitionAllowed = isTableDefinition(item);
  const isEditAllowed =
    (isAsset(item) &&
      sqLicenseManagementStore.hasAssetGroups() &&
      canWriteItem(item) &&
      ((_.find(item.properties, { name: SeeqNames.Properties.TreeType }) as any)?.value === CREATED_BY_SEEQ_WORKBENCH ||
        item.isAssetGroupItem) &&
      _.isEmpty(item.ancestors)) ||
    isEditTableDefinitionAllowed;

  const addAsset = (addMatchingItemsOnly = false, addChildrenOfItemsOfItems = false) => {
    setIsLoading(true);
    const promise = validateItemToAdd({ item })
      ? addAssetOrItem({
          assetOrItem: item,
          addMatchingItemsOnly,
          addItemsOfItemsChildren: addChildrenOfItemsOfItems,
        })
      : Promise.resolve();

    return promise.finally(() => setIsLoading(false));
  };

  const loadTableDefinitionForEdit = (): any => {
    return editTableDefinition(item);
  };

  const loadAssetGroupForEdit = (): any => {
    setDisplayAssetGroupEditor(true);
    return editAssetGroup(item);
  };
  return (
    <>
      {isEditAllowed && (
        <IconWithManagedSpinner
          icon="fa-pencil"
          testId={isAsset(item) ? 'editAssetGroupIcon' : 'editTableDefinitionIcon'}
          disabled={isEditingAssetGroup || isEditingTableDefinition}
          large={true}
          correctSpin={false}
          extraClassNames="searchBtn"
          action={isAsset(item) ? loadAssetGroupForEdit : loadTableDefinitionForEdit}
          actionOnEnter={true}
          tooltip={
            (!isEditingAssetGroup && isAsset(item) && 'SEARCH_DATA.EDIT_ASSET_GROUP_TOOLTIP') ||
            (isEditTableDefinitionAllowed && !isEditingTableDefinition && 'SEARCH_DATA.EDIT_SCALING_TABLE_TOOLTIP')
          }
          tooltipDelay={500}
        />
      )}

      {isAssetGroupView && (
        <>
          {/* assets and "items of items'" parents */}
          {(isAsset(item) || (!isAsset(item) && item.hasChildren)) && (
            <IconWithManagedSpinner
              icon="fc-plus-assets"
              extraClassNames="searchBtn"
              action={() => addAsset(false, !isAsset(item))}
              actionOnEnter={true}
              correctSpin={false}
              large={true}
              disabled={isEditingAssetGroup}
              testId="addAssetIcon"
              tooltip={!isEditingAssetGroup && 'SEARCH_DATA.ADD_ASSET_TO_GROUP_TOOLTIP'}
              tooltipDelay={500}
            />
          )}

          {/* assets and "items of items'" parents */}
          {(isAsset(item) || (!isAsset(item) && item.hasChildren)) && !_.isEmpty(storeAssets) && (
            <IconWithManagedSpinner
              icon="fc-plus-selected"
              extraClassNames="searchBtn"
              action={() => addAsset(true)}
              actionOnEnter={true}
              correctSpin={false}
              large={true}
              disabled={isEditingAssetGroup}
              testId="addAssetIconSameChildrenOnly"
              // TODO CRAB-29079: remove isProtractor check, if test still fails find a better solutions
              tooltip={
                !isEditingAssetGroup && !isProtractor() && 'SEARCH_DATA.ADD_ASSET_TO_GROUP_CHILDREN_MATCH_TOOLTIP'
              }
              tooltipDelay={500}
            />
          )}

          {/* items (including items of items' parents) */}
          {!isAsset(item) && !isTableDefinition(item) && (
            <IconWithManagedSpinner
              icon="fa-plus-circle"
              extraClassNames="searchBtn"
              action={addAsset}
              actionOnEnter={true}
              correctSpin={false}
              large={true}
              testId="addItemToAssetIcon"
              tooltip="SEARCH_DATA.ADD_ASSET_ADD_ITEM_TOOLTIP"
              tooltipDelay={500}
            />
          )}
        </>
      )}
    </>
  );
};

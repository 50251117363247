import _ from 'lodash';
import { formatTime, parseISODate } from '@/datetime/dateTime.utilities';
import { setWorkBook } from '@/workbook/workbook.utilities';
import { getWorksheets } from '@/worksheets/worksheets.utilities';
import { addAssetsProperty } from '@/utilities/httpHelpers.utilities';
import { HOME_SCREEN_TABS, SEARCH_ITEM_LOCATIONS } from '@/main/app.constants';
import { Feature } from '@/licenseManagement/licenseManagement.store';
import moment from 'moment';
import { WorkbookOutputV1 } from 'sdk/model/WorkbookOutputV1';
import { sqItemsApi } from '@/sdk/api/ItemsApi';
import { FilterEnum, sqFoldersApi } from '@/sdk/api/FoldersApi';
import { sqProjectsApi } from '@/sdk/api/ProjectsApi';
import { sqWorkbooksApi } from '@/sdk/api/WorkbooksApi';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { equalsIgnoreCase } from '@/utilities/utilities';
import { warnToast } from '@/utilities/toast.utilities';
import i18next from 'i18next';
import { sqHomeScreenStore, sqLicenseManagementStore, sqWorkbenchStore, sqWorksheetStore } from '@/core/core.stores';
import { ITEM_TYPES, tabFolders } from '@/homescreen/homescreen.constants';
import { DEFAULT_WORKBOOK_STATE, WORKBOOK_DISPLAY } from '@/workbook/workbook.constants';
import { isEveryoneGroupEnabled } from '@/services/systemConfiguration.utilities';
import { setWorkbookDisplayMode } from '@/workbook/workbook.actions';
import { canModifyWorkbook, isAdmin } from '@/services/authorization.service';
import { Item } from '@/tools/histogram/aggregationBin.store';
import { getTabFolder } from '@/utilities/homescreen.helpers';
import { setJournalToOpen } from '@/workbench/workbench.actions';
import { isSystemTest } from '@/core/utilities';
import { StoreWorkbook, StoreWorkbookWithWorksheets } from '@/worksheet/worksheet.types';

/**
 * Returns a placeholder folder object representing the "root" workbench level.
 *
 * @returns {Promise} that resolves with an object representing the root folder.
 */
export function getWorkbenchFolderPlaceholder() {
  return {
    name: i18next.t('WORKBENCH.ROOT'),
    id: null,
    workbookId: null,
  };
}

/**
 * Returns the expected icon for the provided item.
 *
 * @param {Object} item - and object representing a workbench explorer item (Analysis, Topic or Folder)
 * @returns {String} representing the icon css class.
 */
export function getItemIcon(item: Item) {
  let iconClass = 'fc-analysis';

  if (_.get(item, 'type') === ITEM_TYPES.FOLDER) {
    iconClass = 'fa-folder-open';
  } else if (_.get(item, 'type') === ITEM_TYPES.PROJECT) {
    iconClass = 'fc-seeq-datalab';
  } else if (_.get(item, 'type') === ITEM_TYPES.TOPIC) {
    iconClass = 'fc-report';
  } else if (_.get(item, 'type') === ITEM_TYPES.JOURNAL) {
    iconClass = 'fa-book workbenchAnalysis';
  } else if (_.get(item, 'type') === ITEM_TYPES.REPORT) {
    iconClass = 'fa-book workbenchReport';
  }
  if (!canModifyWorkbook(item, false)) {
    if (_.get(item, 'type') === ITEM_TYPES.FOLDER) {
      iconClass = 'fa-kit fa-sharp-regular-folder-open-lock';
    } else {
      iconClass += '-lock';
    }
  }
  return iconClass;
}

/**
 * Returns a translated breadcrumb name if translationKey is available.
 *
 * @param {String} crumb - a folder from the breadcrumbs for translating
 * @returns {String} representing a translated/original folder name in the breadcrumb.
 */
export function translateBreadcrumb(
  crumb: {
    translationKey: string;
    id: string;
    name: string;
  },
  isMyFolder = false,
  corporateFolderId = '',
) {
  if (!crumb) {
    return;
  }

  if (crumb.translationKey) {
    return i18next.t(`HOME_SCREEN.TABS.${crumb.translationKey}`);
  } else {
    if (crumb.id === corporateFolderId) {
      return i18next.t('HOME_SCREEN.LOCATION.CORPORATE');
    } else if (isMyFolder) {
      return i18next.t('HOME_SCREEN.LOCATION.MY_FOLDER');
    } else {
      return crumb.name;
    }
  }
}

/**
 * Get the collection of workbench items (folders, topics, analyses).
 *
 * @param {String} [filter] - Specify what to be returned: 'owner' for all workbooks owned by the current user,
 *   'shared' for all workbooks shared with the current user or 'all' (or undefined) to return workbooks for all users
 * @param {String} [sortOrder] - Specify the column and sort direction by which to sort the workbooks. Format is the
 *   field name followed by "asc" or "desc". For example, "updatedAt desc"
 * @param {String} [folderId] - Id of the folder
 * @param {Boolean} [isArchived] - True to return only archived items, false to return only unarchived items
 * @param {String} [cancellationGroup] - Id to use for request cancellation
 * @return {Promise} Resolves with the collection of names and ids for the workbooks
 */
export function getWorkbenchItems(
  filter?: string,
  sortOrder?: string,
  folderId?: string,
  cancellationGroup?: string,
  isArchived = false,
) {
  // This method is only used by the workstep analyzer...
  const params = { filter: filter as unknown as FilterEnum, sortOrder, limit: 10000, cancellationGroup };

  if (folderId) {
    _.assign(params, { folderId });
  }
  if (isArchived) {
    _.assign(params, { isArchived });
  }

  return sqFoldersApi.getFolders(params).then((response) => _.map(response.data.content, toWorkbook));
}

/**
 * Helper function that creates a workbook object to be dispatched to a store
 *
 * @param {Object} workbook - The workbook from the API
 * @returns {Object} Workbook for a store
 */
export function toWorkbook(workbook: WorkbookOutputV1): StoreWorkbook {
  return _.assign(workbook, {
    workbookId: workbook.id,
    createdAt: parseISODate(workbook.createdAt as string).valueOf(),
    updatedAt: parseISODate(workbook.updatedAt as string).valueOf(),
  });
}

/**
 * Creates a workbook for the current user with default workbook state initialized.
 *
 * @param {String} name - The workbook name
 * @param {String} data - A stringified object to be stored on the workbook data property
 * @param {String} [branchFrom] - The ID of the workbook from which to branch if wanting to copy an existing
 *   workbook
 * @param {String} [folderId] - Id of the folder
 */
export function createWorkbook(name: string, branchFrom: string, folderId: string, type: string) {
  if (!isSystemTest()) {
    setJournalToOpen(null);
  }
  return sqWorkbooksApi
    .createWorkbook({ name, branchFrom, folderId, type })
    .then((response) => response.data)
    .then((data) => setWorkBook(data.id, DEFAULT_WORKBOOK_STATE).then(() => data))
    .then((workbook) => {
      setWorkbookDisplayMode(WORKBOOK_DISPLAY.EDIT);
      return toWorkbook(workbook);
    });
}

/**
 * Returns the specified folder object. Note that this does not return the contents of the folder.
 *
 * @param {String} folderId - id of the folder to get
 * @returns {Promise<any>} that resolves with the folder object. Also ensures that the folder object is
 *   assigned a workbookId as every item is assumed to have a workbookId. Ideally we'd just refactor this to id or
 *   itemId but that seems a little overwhelming right now.
 */
export function getFolder(folderId: string) {
  return sqFoldersApi.getFolder({ folderId }).then(({ data }) => _.assign(data, { workbookId: data.id }));
}

/**
 * Get the properties of a Workbook and the Workbook's worksheets
 *
 * @param workbookId - The GUID of the workbook to be fetched
 * @param [options] - object container for options
 * @param [options.includeWorkstepId = false] - true to have the returned worksheets include the current workstep ID
 * @param [options.includeArchivedWorksheets = false] - true to return the workbook's archived worksheets
 * @return {promise} resolves with the name and worksheets of the workbook
 */
export function getWorkbook(
  workbookId: string,
  {
    includeWorkstepId = false,
    includeArchivedWorksheets = false,
  }: {
    includeWorkstepId?: boolean;
    includeArchivedWorksheets?: boolean;
  } = {},
): Promise<StoreWorkbookWithWorksheets> {
  return getWorkbookOnly(workbookId)
    .then((workbook) => {
      if (_.get(workbook, 'isArchived', false)) {
        includeArchivedWorksheets = true;
      }
      return workbook;
    })
    .then((workbook) => {
      return getWorksheets(workbookId, includeWorkstepId, includeArchivedWorksheets).then((worksheets) => {
        // Workbooks that were archived prior to R53 didn't have their unarchived worksheets archived along with
        // them. If a workbook existed before R53 and we open it from the trash folder, we need to return its
        // unarchived worksheets instead
        if (_.isEmpty(worksheets) && includeArchivedWorksheets) {
          return getWorksheets(workbookId, includeWorkstepId, false).then((worksheets) => {
            return _.assign(toWorkbook(workbook), { worksheets });
          });
        }
        return _.assign(toWorkbook(workbook), { worksheets });
      });
    });
}

/**
 * Gets a Workbook without its worksheets
 *
 * @param {String} workbookId - The GUID of the workbook to be fetched
 * @return {promise} resolves with the workbook
 */
export function getWorkbookOnly(workbookId: string) {
  return sqWorkbooksApi.getWorkbook({ id: workbookId }).then(({ data }) => data);
}

export function getWorkbenchItem(itemId: string) {
  return sqFoldersApi.getFolders({ ids: [itemId] }).then((response) => _.get(response, 'data.content[0]'));
}

/**
 * Creates a new folder.
 *
 * @param name - the name of the folder.
 * @param [parentFolderId] - the id of the parent folder. If no parent is provided the folder is created at
 *   the workbench level.
 * @param [branchFrom] - the id of the folder to duplicate, if desired.
 * @param [ownerId] - the id of the new owner
 * @returns that resolves once the folder has been created and the response was converted to
 *   a proper "workbook" object
 */
export function createFolder(
  name: string,
  parentFolderId: string,
  branchFrom: string | undefined = undefined,
  ownerId: string | undefined = undefined,
) {
  return sqFoldersApi
    .createFolder({ name, parentFolderId, branchFrom, ownerId })
    .then(({ data }) => data)
    .then(toWorkbook);
}

export function createProject(name: string, folderId: string) {
  return sqProjectsApi
    .createProject({ name, folderId })
    .then(({ data }) => data)
    .then(toWorkbook);
}

/**
 * Moves an item to the specified folder.
 *
 * @param {String} itemId - id of the item to move.
 * @param {String} destinationFolderId - the id of the folder the item should be moved to.
 * @returns {Promise} that resolves when the item was moved.
 */
export function moveItem(itemId: string, destinationFolderId: string) {
  return sqFoldersApi.moveItemToFolder({
    folderId: destinationFolderId,
    itemId,
  });
}

/**
 * This function is used to move an item to the root level "folder" aka "home".
 *
 * @param {String} assignedFolder - id of the folder the item currently is assigned to.
 * @param {String} itemId - the id of the item to move.
 */
export function removeContentFromFolder(assignedFolder: string, itemId: string) {
  return sqFoldersApi.removeItemFromFolder({
    folderId: assignedFolder,
    itemId,
  });
}

/**
 * Sets a property on a workbook
 *
 * @param {String} property - The name of the property
 * @param {String} workbookId - The ID of the workbook
 * @param {*} value - The value of the property
 * @returns {Promise} - Resolves when the property is set
 */
export function setProperty(property: string, workbookId: string, value: unknown) {
  // Description is an optional property
  if (property === 'Description' && _.isEmpty(value)) {
    return sqItemsApi.deleteProperty({ id: workbookId, propertyName: property }).catch(_.noop);
  } else {
    return sqItemsApi.setProperty({ value }, { id: workbookId, propertyName: property });
  }
}

/**
 * Sets the archive property on a workbook.
 *
 * @param {String} workbookId - The ID of the workbook
 * @param {Boolean} isArchived - The value of the property
 * @returns {Promise} - Resolves when the property is set
 */
export function setArchived(workbookId: string, isArchived: boolean) {
  return setProperty(SeeqNames.Properties.Archived, workbookId, isArchived);
}

/**
 * Toggles an item's favorite status.
 *
 * @param {Object} item - representing a workbench explorer item.
 * @param {String} item.workbookId - id of the item.
 * @returns {Promise} - resolves when the favorite status was set.
 */
export function toggleIsPinned(
  item: Item & {
    workbookId: string;
  },
) {
  if (_.get(item, 'isPinned')) {
    return sqItemsApi.unpinItem({ itemId: item.workbookId });
  } else {
    return sqItemsApi.pinItem({ itemId: item.workbookId });
  }
}

/**
 * Gets the recently accessed list for the workbook.
 *
 * @param {String} workbookId - The ID of the workbook
 * @return {Promise} Resolves with the list of recently accessed items.
 */
export function getRecentlyAccessed(workbookId: string) {
  return sqWorkbooksApi
    .getRecentlyAccessed({ workbookId })
    .then((response) => response.data)
    .then((data) => addAssetsProperty(data));
}

/**
 * Adds an item to the recently accessed list for the workbook.
 *
 * @param {String} workbookId - The ID of the workbook
 * @param {String} id - The ID of the item that was recently accessed
 * @return {Promise} Resolves with the new list of recently accessed items.
 */
export function addRecentlyAccessed(workbookId: string, id: string) {
  return sqWorkbooksApi
    .addRecentlyAccessed({ workbookId, itemId: id })
    .then((response) => response.data)
    .then((data) => addAssetsProperty(data));
}

/**
 * Returns true if item is a folder, otherwise false (for workbooks/topics)
 *
 * @param {Object} item - Object to test
 * @param {String} item.type - Property containing the item type
 * @returns {boolean} True if folder, otherwise false
 */
export function isFolder(item: Item) {
  return _.get(item, 'type') === ITEM_TYPES.FOLDER;
}

export function isProject(item: Item) {
  return _.get(item, 'type') === ITEM_TYPES.PROJECT;
}

/**
 * Opens a project in a new window.
 * @param {String} projectId - the ID of the project to open
 * @param {MouseEvent | KeyboardEvent} [event] - an optional event used to determine keys that are depressed
 * @returns {Promise} a promise that resolves once the new project window has been opened or once an error
 * notification has been displayed
 */
export function openProject(projectId: string, event?: MouseEvent | KeyboardEvent): Promise<any> {
  if (sqLicenseManagementStore.hasValidFeature(Feature.Data_Lab)) {
    const target = sqWorkbenchStore.preferNewTab || event?.ctrlKey || event?.metaKey ? '_blank' : '_self';
    return Promise.resolve(window.open(`/data-lab/${projectId.toUpperCase()}`, target)).then(() =>
      updateOpenedAt(projectId),
    );
  } else {
    warnToast({ messageKey: 'WORKBENCH.DATA_LAB_DISABLED' });
    return Promise.resolve();
  }
}

/**
 * Logs the current time the time when the user last accessed the item.
 * @param {String} id - the ID of the item that was opened
 */
export function updateOpenedAt(id: string) {
  if (id) {
    sqItemsApi.setItemUserAttributes({ openedAt: moment().toISOString() }, { id });
  }
}

/**
 * Filters out:
 * 'Corporate' when the Everyone group is disabled
 * admin only options for non-admins
 * 'Recent' if recent is false
 * @param {Object} constToFilter - either of the constants HOME_SCREEN_TABS_AND_TRANSLATION_KEYS or
 *   SEARCH_LOCATION_OPTIONS
 * @param {Boolean} recent - filter out 'recent'
 */
export function getFilteredLocations(
  constToFilter: {
    value: string;
  }[],
  recent = true,
) {
  if (_.isArray(constToFilter)) {
    let filteredConst = isAdmin() ? constToFilter : _.reject(constToFilter, { adminOnly: true });
    filteredConst = recent
      ? filteredConst
      : _.reject(filteredConst, (option) => _.toUpper(option.value) === HOME_SCREEN_TABS.RECENT);

    return filteredConst;
  }
  return constToFilter;
}

/**
 * Creates a default folder name.
 */
export function getDefaultFolderName() {
  moment.locale(_.truncate(sqWorkbenchStore.userLanguage, { length: 2, omission: '' }));
  return `${i18next.t('ITEM_TYPES.FOLDER')} ${formatTime(new Date(), sqWorksheetStore.timezone)}`;
}

export function getTabFolderName(id: string) {
  if (_.isNil(id)) {
    return Promise.resolve(undefined);
  }

  let folderPromise;
  const tabFolderKeys = _.keys(sqHomeScreenStore.tabFolders);
  const tabKey = _.findKey(sqHomeScreenStore.tabFolders, (tabFolder) =>
    tabFolder ? equalsIgnoreCase(tabFolder.id, id) : undefined,
  );

  if (!_.isUndefined(tabKey)) {
    return Promise.resolve(tabKey);
  } else {
    const useTabFolders = tabFolders(isAdmin(), isEveryoneGroupEnabled());
    if (useTabFolders.length > tabFolderKeys.length) {
      const missingTabFolders = _.difference(useTabFolders, tabFolderKeys);
      folderPromise = Promise.all(_.map(missingTabFolders, (tabFolderName) => getTabFolder(tabFolderName)));
    } else {
      folderPromise = Promise.resolve();
    }

    return folderPromise.then(() => {
      return _.findKey(sqHomeScreenStore.tabFolders, (tabFolder) =>
        tabFolder ? equalsIgnoreCase(tabFolder.id, id) : undefined,
      );
    });
  }
}

export function getFolderRoot(currentTab: string) {
  switch (currentTab) {
    case HOME_SCREEN_TABS.USERS:
      return SEARCH_ITEM_LOCATIONS.USERS;
    case HOME_SCREEN_TABS.MY_FOLDER:
      return SEARCH_ITEM_LOCATIONS.MY_FOLDER;
    case HOME_SCREEN_TABS.SHARED:
      return SEARCH_ITEM_LOCATIONS.SHARED_OR_PUBLIC;
    case HOME_SCREEN_TABS.CORPORATE:
      return SEARCH_ITEM_LOCATIONS.CORPORATE;
    default:
      return null;
  }
}

export function getFolderName(currentTab: string) {
  switch (currentTab) {
    case HOME_SCREEN_TABS.USERS:
      return 'HOME_SCREEN.LOCATION.USERS';
    case HOME_SCREEN_TABS.MY_FOLDER:
      return 'HOME_SCREEN.LOCATION.MY_FOLDER';
    case HOME_SCREEN_TABS.SHARED:
      return 'HOME_SCREEN.LOCATION.SHARED_OR_PUBLIC';
    case HOME_SCREEN_TABS.CORPORATE:
      return 'HOME_SCREEN.LOCATION.CORPORATE';
    default:
      return null;
  }
}

export function formatJournalReportContent(searchText: string, id: string) {
  return sqItemsApi
    .getProperty({
      id,
      propertyName: SeeqNames.Properties.PlainTextDocument,
    })
    .then((results) => {
      let content = results?.data?.value;
      if (_.includes(_.toLower(content), _.toLower(searchText)) && searchText) {
        // if the searchText is found, set setJournalReportContent to the portion of the document content
        // where the searchText is
        content = content?.substring(
          _.toLower(content).indexOf(searchText) - 100,
          _.toLower(content).indexOf(searchText) + 200,
        );
      } else {
        // if the searchText is empty or not found, set setJournalReportContent to a portion of the document
        // content
        content = content?.substring(0, 300);
      }
      return content;
    });
}

/**
 * Sets up a folder from a specific id and returns the folder contents, including a full list of ancestors with
 * the root ancestor, as well. There are some cases in the Shared and User folders where the root ancestors need to be
 * manually added to the folder ancestor list.
 * @param {String} folderId - the nested folder id
 * @param {String} locationValue - the tab where the nested folder is located
 * @param {Boolean} isInAdvancedSearchBreadcrumbClick - checks if the function is being called when inside the
 * home screen advanced search and the user clicks on one of the breadcrumbs
 */
export function formatNestedFolderWithAncestors(
  folderId: string,
  locationValue: string,
  isInAdvancedSearchBreadcrumbClick: boolean,
) {
  const locationUsers = locationValue === SEARCH_ITEM_LOCATIONS.USERS;
  return sqFoldersApi.getFolder({ folderId }).then((response) => {
    return sqFoldersApi.getAncestors({ folderId, root: locationValue }).then((folderTree) => {
      return sqFoldersApi
        .getFolder({
          // This endpoint only has special values of 'corporate', 'mine', and 'users', so when the value
          // would be 'sharedOrPublic' this temporarily gets set to an id that is allowed
          folderId:
            folderTree.data.id === SEARCH_ITEM_LOCATIONS.SHARED_OR_PUBLIC
              ? SEARCH_ITEM_LOCATIONS.USERS
              : folderTree.data.id,
        })
        .then((folderTreeItem) => {
          return sqFoldersApi
            .getFolders({
              filter: FilterEnum.SharedOrPublic,
              types: ['Folder'],
            })
            .then((sharedFolderResponse) => {
              // This manually gets the shared folder to use as the root ancestor when a shared subfolder is
              // missing the root
              const sharedFolder = _.filter(
                sharedFolderResponse.data?.content?.[0]?.ancestors ?? [],
                (ancestor) => ancestor.translationKey === 'SHARED',
              );
              const firstAncestor =
                folderTree.data.id !== SEARCH_ITEM_LOCATIONS.SHARED_OR_PUBLIC ||
                (isInAdvancedSearchBreadcrumbClick && locationValue !== SEARCH_ITEM_LOCATIONS.SHARED_OR_PUBLIC)
                  ? folderTreeItem.data
                  : sharedFolder[0];
              return sqFoldersApi
                .getFolders({
                  filter: FilterEnum.Mine,
                  types: ['Folder'],
                })
                .then((myFolderResponse) => {
                  // When in the user folder and a nested folder is selected that is not in the folder of the
                  // current user, this gets the correct parent user folder to set the second ancestor.
                  // When in the user folder and a nested folder is selected within the current user's folder,
                  // this gets the folder of the current user to set the second ancestor.
                  const userFolderAncestor =
                    locationUsers && (response.data.ancestors?.length ?? 0) === 0
                      ? _.filter(
                          folderTree.data.subfolders,
                          (subfolder) => subfolder.id === response.data.parentFolderId,
                        )
                      : _.filter(
                          myFolderResponse.data.content?.[0]?.ancestors ?? [],
                          (ancestor) => ancestor.translationKey === 'MY_FOLDER',
                        );
                  const secondAncestor =
                    (folderTree.data.id === SEARCH_ITEM_LOCATIONS.MY_FOLDER &&
                      locationUsers &&
                      response.data.id !== userFolderAncestor[0]?.id) ||
                    (locationUsers && (response.data.ancestors?.length ?? 0) === 0)
                      ? userFolderAncestor[0]
                      : undefined;
                  const folderItem = _.assign({}, response.data, {
                    ancestors:
                      secondAncestor && isInAdvancedSearchBreadcrumbClick
                        ? [firstAncestor, secondAncestor, ...(response.data.ancestors ?? [])]
                        : [firstAncestor, ...(response.data.ancestors ?? [])],
                  });
                  return folderItem;
                });
            });
        });
    });
  });
}
